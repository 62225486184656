<template>
  <div class="ornaOpen">
    <div class="luckySkins">
      <div class="luckySkins-title">
        {{ $t("幸运饰品") }}
      </div>

      <!-- 幸运饰品中心区域 -->
      <div class="upgrade__body">
        <!-- 左边选择区域 -->
        <div
          class="upgrade__selected-container upgrade__selected-container--left"
        >
          <!-- 没有选中饰品的时候 -->
          <div class="flex flex-col items-center" v-if="disabledK">
            <img src="../assets/img/weapons.svg" alt="" />
            <h3 class="mt-6 font-medium text-gray-100 text-sbase">
              {{ $t("选择你的物品") }}
            </h3>
            <h4 class="text-gray-500 text-ssm">{{ $t("要升级的物品") }}</h4>
          </div>
          <div class="upgrade__add-item" v-if="!disabledK">
            <img :src="obj.imageUrl" alt="" />
            <svg
              viewBox="0 0 152 152"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="absolute top-1/2 left-1/2 w-8/12 -translate-x-1/2 -translate-y-1/2 h-8/12"
            >
              <path
                d="M11.1821 38.5774L76 1.1547L140.818 38.5774V113.423L76 150.845L11.1821 113.423V38.5774Z"
                stroke="url(#board-grade-11-paint0)"
                stroke-width="2"
              ></path>
              <path
                opacity="0.25"
                d="M76.0001 29.5562L116.222 52.7784V99.2228L76.0001 122.445L35.778 99.2228V52.7784L76.0001 29.5562Z"
                fill="url(#board-grade-11-paint1)"
              ></path>
              <defs>
                <linearGradient
                  id="board-grade-11-paint0"
                  x1="76"
                  y1="0"
                  x2="76"
                  y2="152"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4b69ff" stop-opacity="0"></stop>
                  <stop offset="1" stop-color="#4b69ff"></stop>
                </linearGradient>
                <linearGradient
                  id="board-grade-11-paint1"
                  x1="76.0001"
                  y1="29.5562"
                  x2="76.0001"
                  y2="122.445"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4b69ff" stop-opacity="0"></stop>
                  <stop offset="1" stop-color="#4b69ff"></stop>
                </linearGradient>
              </defs>
            </svg>
            <div class="close" @click="goOrnamentOpen('', 'del')">
              <svg
                class="close-icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
          <div class="upgrade__selected-info" v-if="!disabledK">
            <div class="upgrade__selected-text">
              <p>{{ getShortName(obj) }}</p>
              <p class="text-sm text-gray-400">
                {{ getExteriorName(obj) }}
              </p>
            </div>
            <div class="upgrade__selected-amount">
              <p>{{ obj.price }}</p>
            </div>
          </div>
        </div>
        <!-- 中间区域 -->
        <div class="upgrade__circle-wrapper">
          <div class="upgrade__circle">
            <div
              class="upgrade__circle-display"
              style="--circle-result-percent: 0"
            >
              <div
                class="upgrade__circle-indicator"
                :class="disabled ? 'upgrade__circle_start' : ''"
                :style="[
                  { '--circle-result-percent': value3 * 3.6 + 'deg' },
                  { '--bg-qudus': value4 + 'deg' },
                ]"
              >
                <div class="upgrade__circle-needle-wrapper">
                  <div class="upgrade__circle-needle"></div>
                </div>
              </div>
              <div class="upgrade__chance-fill"></div>
              <div class="upgrade__circle-inner">
                <div class="upgrade__circle-inner-border"></div>
                <div
                  class="upgrade__chance-pie"
                  :style="[
                    {
                      '--chance-pie-percent': `${value3}%`,
                    },
                    {
                      '--chance-pie-more-percent': `${-(value3 - 1)}%`,
                    },
                  ]"
                ></div>
                <div class="upgrade__circle-text">
                  <div class="upgrade__chance" v-if="disabledK">
                    <p class="text-2xl">{{ $t("选择你的饰品") }}</p>
                  </div>
                  <!-- 成功率 -->
                  <div class="upgrade__chance" v-else>
                    <p class="mb-1 text-5xl text-white">{{ value3 }}%</p>
                    <p class="text-gray-500">
                      {{ $t("成功率") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 手机端进度条 -->
        <!-- 电脑端进度条 -->
        <div class="upgrade__use-balance" v-if="!disabledK && !showNav">
          <div class="flex justify-between items-center">
            <div>{{ $t("设置百分比") }}</div>
            <div>
              {{ ((price * value3) / 100).toFixed(2) }}
            </div>
          </div>
          <el-slider
            v-model="value3"
            :show-tooltip="false"
            :min="5"
            :max="75"
            :step="5"
            @change="changeNum"
          ></el-slider>
        </div>
        <!-- 手机端按钮 -->
        <div
          class="button goAdd"
          v-if="!showNav"
          :class="disabledK ? 'disabled' : ''"
        >
          <span @click="upgrade"
            >{{ $t("升级按钮") }}
            <svg
              data-v-eebb099b=""
              class="w-4.5 h-4.5"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                data-v-eebb099b=""
                d="M5.99316 3.48657H13.531V11.0421"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                data-v-eebb099b=""
                d="M13.531 3.48657L3.46875 13.5488"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path></svg
          ></span>
        </div>
        <!-- 右边选择区域 -->
        <div
          class="upgrade__selected-container upgrade__selected-container--right"
        >
          <div class="flex flex-col items-center">
            <img src="../assets/img/weapons.svg" alt="" />
            <h3 class="mt-6 font-medium text-gray-100 text-sbase">
              {{ $t("生成随机饰品") }}
            </h3>
          </div>
        </div>
      </div>
      <!-- 电脑端进度条 -->
      <div class="upgrade__use-balance" v-if="!disabledK && showNav">
        <div class="flex justify-between items-center">
          <div>{{ $t("设置百分比") }}</div>
          <div>
            {{ ((price * value3) / 100).toFixed(2) }}
          </div>
        </div>
        <el-slider
          v-model="value3"
          :show-tooltip="false"
          :min="5"
          :max="75"
          :step="5"
          @change="changeNum"
        ></el-slider>
      </div>
      <!-- 电脑端按钮 -->
      <div class="button goAdd" v-if="showNav">
        <span @click="upgrade"
          >{{ $t("升级按钮") }}
          <svg
            data-v-eebb099b=""
            class="w-4.5 h-4.5"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              data-v-eebb099b=""
              d="M5.99316 3.48657H13.531V11.0421"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              data-v-eebb099b=""
              d="M13.531 3.48657L3.46875 13.5488"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path></svg
        ></span>
      </div>
      <!-- 饰品选择区域 -->
      <div class="boxlist">
        <!-- 标题 -->
        <div class="title">
          <div>
            {{ $t("选择饰品") }}
          </div>
          <div class="search-box">
            <div class="roll-price">
              <el-input
                :placeholder="$t('搜索')"
                v-model="searchKey"
                style="
                  width: calc(100% - 86px);
                  margin-right: 12px;
                  max-width: 280px;
                  min-width: 255px;
                "
                class="input1"
              >
                <i
                  slot="prefix"
                  class="el-input__icon el-icon-search input1-i"
                  @click="getList(page)"
                ></i>
              </el-input>
              <div class="sort-block" @click="listSort">
                <div style="font-size: 14px; font-weight: bold">价格</div>
                <div class="sort-icon">
                  <img
                    src="../assets/img/arrow-small-down.svg"
                    :class="{ desc: priceSort == 'desc' }"
                    alt=""
                  />
                </div>
              </div>
              <!-- 最小价格 -->
              <el-input
                class="price"
                :placeholder="$t('最低价')"
                type="number"
                v-model="MinPrice"
                clearable
              ></el-input>
              <!-- 类型下拉菜单 -->
              <div class="type-down" v-if="showNav">
                <el-button class="type-button" @click="toggleDropdown">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-4"
                  >
                    <path
                      d="M10 3.75a2 2 0 10-4 0 2 2 0 004 0zM17.25 4.5a.75.75 0 000-1.5h-5.5a.75.75 0 000 1.5h5.5zM5 3.75a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 01.75.75zM4.25 17a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5h1.5zM17.25 17a.75.75 0 000-1.5h-5.5a.75.75 0 000 1.5h5.5zM9 10a.75.75 0 01-.75.75h-5.5a.75.75 0 010-1.5h5.5A.75.75 0 019 10zM17.25 10.75a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5h1.5zM14 10a2 2 0 10-4 0 2 2 0 004 0zM10 16.25a2 2 0 10-4 0 2 2 0 004 0z"
                    ></path>
                  </svg>
                </el-button>
                <div class="dropdown type-dropdown" v-show="showDropdown">
                  <el-checkbox-group v-model="typeList">
                    <el-checkbox
                      :label="item.id"
                      v-for="item in selList"
                      :key="item.id"
                      >{{ item.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <template>
                <!-- 手机端 筛选 -->
                <div
                  class="filter-button"
                  v-if="!showNav"
                  @click="showMobileFilters()"
                >
                  {{ $t("过滤") }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="boxs">
          <template v-if="list.length > 0">
            <div
              ref="box"
              class="box"
              v-for="item in list"
              :key="item.id"
              :style="[
                { '--ww': boxWidth + 'px' },
                { 'border-top-color': item.rarityColor },
              ]"
            >
              <div class="price">
                <img src="./img/money.png" alt="" />
                <span>{{ item.price }}</span>
              </div>
              <div class="wuqi">
                <img :src="item.imageUrl" alt="" />
              </div>
              <div class="description">
                <div class="c2">{{ getShortName(item) }}</div>
                <div class="c1">{{ getExteriorName(item) }}</div>
              </div>
              <div
                class="transition-opacity delete"
                v-show="obj.id == item.id"
                @click="goOrnamentOpen('', 'del')"
              >
                <img src="../assets/img/item-selected.svg" alt="" />
              </div>
              <div
                class="transition-opacity add"
                v-show="obj.id != item.id"
                @click="goOrnamentOpen(item)"
              >
                <img src="../assets/img/item-select.svg" alt="" />
              </div>
            </div>
          </template>
          <template v-if="list.length == 0">
            <div class="empty">
              <p class="mb-2 text-xl">{{ $t("哦") }}...</p>
              <p class="mb-6 text-gray-500">
                {{ $t("没有符合所选条件的项目") }}
              </p>
            </div>
          </template>
        </div>
        <div class="pagination">
          <pagination
            v-if="total > 0"
            :total="total"
            :currentPage="page"
            :perPage="pageSize"
            :showNav="showNav"
            @current-change="currentChange"
          ></pagination>
        </div>
      </div>
    </div>

    <div class="win-box" v-if="winState">
      <mywin
        :winList="list4"
        :winState="winState"
        :player_skins_ids="player_skins_ids"
        :isis="false"
        @winexchange="winexchange($event)"
        @winget="winget($event)"
        @winX="winX($event)"
      ></mywin>
      <!-- <div>hhhhhh</div> -->
    </div>

    <audio
      controls
      ref="notify"
      class="audio"
      loop="loop"
      style="display: none"
    >
      <source src="../assets/audio/bgm_zhuanbei.mp3" />
    </audio>
    <audio
      controls
      ref="notifys"
      class="audio"
      loop="loop"
      style="display: none"
    >
      <source src="../assets/audio/succeed.mp3" />
    </audio>
    <MyHomebot></MyHomebot>
    <div class="mobile-skins-filter" v-show="!showNav ? mobileFilters : false">
      <div class="mobile-filters-header">
        <div class="close" @click="closeMobileFilters()">
          <img src="../assets/img/cross-small.png" width="25" height="25" />
          <span>{{ $t("过滤") }}</span>
        </div>
        <div class="clear-button ng-binding" @click="clearFilters()">
          {{ $t("清除过滤") }}
        </div>
      </div>
      <div class="mobile-filters-body">
        <div class="mobile-type-list">
          <span class="ng-binding">{{ $t("类型") }}</span>
        </div>
        <el-checkbox-group v-model="mobileTypeList">
          <el-checkbox
            :label="item.id"
            v-for="item in selList"
            :key="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="mobile-filters-footer">
        <button
          class="mobile-filters-button ng-binding"
          @click="saveMobileFilters"
        >
          {{ $t("保存") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import myslide from "@/components/my_slide1.vue";
import navheader from "@/components/navheader.vue";
import Utils from "../assets/js/util.js";
import mywin from "@/components/my_win.vue";
import MyHomebot from "./my_homebot.vue";
import pagination from "@/components/pagination.vue";
import debounce from "@/utils/debounce.js";
export default {
  components: {
    myslide,
    mywin,
    navheader,
    MyHomebot,
    pagination,
  },
  props: ["yidongmenu", "showNav"],
  data() {
    return {
      mobileFilters: false,
      mobileTypeList: [],
      typeList: [],
      showDropdown: false,
      priceSort: "asc",
      disabledK: true,
      boxWidth: 180,
      pirce1: "",
      pirce2: "",
      search: "",
      list: [],
      classList: [],
      selList: [],
      listSlide: [],
      classObj: [
        {
          name: "全部",
          flag: 0,
          skin_type_id: "",
          state: true,
        },
      ],

      // 0 未排序  desc反序，ace正序
      sortType: "asc",
      // 关键词
      searchKey: "",
      // 品质
      pzList: [],
      // 外观
      wgList: [],
      // 品质选中
      rarity: "不限",
      // 外观选中
      exterior: "不限",
      // 主分类id
      classId: "19",
      // 小分类id
      subClassId: "all",
      // 推荐
      recommend: 1,
      openWidth: 8610,
      openAnimState: false,
      openAnimList: [],
      disabled: false,
      winFalse: true,
      loading: false,
      pirce1: "",
      openBox: false,
      pirce2: "",
      search: "",
      value3: 5,
      wodes: 1,
      tanchuang: false,
      value4: 2000,
      // list: [
      //   {
      //     name: 1,
      //   },
      //   {
      //     name: 12,
      //   },
      //   {
      //     name: 13,
      //   },
      //   {
      //     name: 14,
      //   },
      //   {
      //     name: 15,
      //   },
      //   {
      //     name: 16,
      //   },
      //   {
      //     name: 17,
      //   },
      //   {
      //     name: 18,
      //   },
      //   {
      //     name: 19,
      //   },
      //   {
      //     name: 111,
      //   },
      //   {
      //     name: 123,
      //   },
      //   {
      //     name: 143,
      //   },
      // ],
      MinPrice: "",
      MaxPrice: "",
      list4: [],
      winState: false,
      price: "",
      animState: false,
      obj: "",
      noWin: {
        name: "赠品",
        price: "0.01",
        img: require("../assets/img/moneyback.png"),
        imageUrl: require("../assets/img/moneyback.png"),
        class: "img-class",
        back: require("../assets/img/box-skins-white.png"),
      },
      valueState: false,
      sound: {
        maxIcon: require("../assets/img/SOUNDPLUS.png"),
        minIcon: require("../assets/img/SOUNDMINUS.png"),
        status: true, // true： 开启声音 false: 关闭声音
        icon: require("../assets/img/SOUNDPLUS.png"),
      },
      showanim: true, // 是否显示箱子列表
      // total: 0,
      page: 1,
      pageSize: 21,
      tableData: [],
      total: 0,
      numSquares: 115, // 调整方块数量
      circleRadius: 125, // 圆的半径，根据你的情况调整
      circleRadiuss: 110, // 圆的半径，根据你的情况调整
      skin_id: this.$route.query.skin_id,
      isbot: false,
      player_skins_ids: [],
      content: "",
      language:
        localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "zh",
    };
  },
  watch: {
    typeList: {
      handler: debounce(function (value) {
        this.page = 1;
        this.getList();
      }, 500),
    },
    searchKey: {
      handler: debounce(function (value) {
        this.page = 1;
        this.getList();
      }, 500),
    },
    MinPrice: {
      handler: debounce(function (value) {
        this.page = 1;
        this.getList();
      }, 500),
    },
    obj: {
      handler(value) {
        if (this.obj.length == 0) {
          return (this.disabledK = true);
        } else {
          return (this.disabledK = false);
        }
      },
    },

    // 监听一级分类选中获取二级分类
    classId: {
      handler(value) {
        if (!value) return;
        this.classList = [
          {
            name: "全部",
            id: "all",
          },
        ];
        this.subClassId = "all";
        this.$axios
          .post(
            "/index/Lucky/subclassList",
            this.$qs.stringify({
              type_id: value,
            })
          )
          .then((res) => {
            if (res.data.status) {
              this.classList.push(...res.data.data.list);
              this.subClassId = this.classList[0].id;
            }
          });
      },
    },
  },
  computed: {
    // disabledK() {
    //   console.log(this.obj.length==0, "this.disabled");
    //   if (this.obj.length==0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    angles() {
      const angles = [];
      const startAngle = -Math.PI / 2; // 从圆的左上方开始
      for (let i = 0; i < this.numSquares; i++) {
        const angle = startAngle + (i / this.numSquares) * 2 * Math.PI;
        angles.push(angle);
      }
      return angles;
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   if (this.showNav) {
    //     this.openWidth =
    //       this.openWidth -
    //       this.$refs.openLine.getBoundingClientRect().left -
    //       950;
    //   } else {
    //     this.openWidth =
    //       this.openWidth -
    //       this.$refs.openLine.getBoundingClientRect().left -
    //       100;
    //   }
    // });

    // this.getSkinDetail(this.obj.id);
    this.getluckyhist1(this.page);
    this.getList();
    this.getListClass();
    this.getTagAndPz();
    // console.log(this.tableData, "列表数据");
    this.getRule();
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        document.addEventListener("click", this.handleOutsideClick);
      } else {
        document.removeEventListener("click", this.handleOutsideClick);
      }
      this.$forceUpdate();
    },
    handleOutsideClick(event) {
      const dropdownElement = document.querySelector(".type-dropdown");
      const buttonElement = document.querySelector(".type-button");

      // 检查点击是否发生在下拉菜单或按钮之外
      if (
        !dropdownElement.contains(event.target) &&
        !buttonElement.contains(event.target)
      ) {
        this.showDropdown = false;
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    getShortName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.shortName;
        case "en":
          return item.english_shortName;
        default:
          return item.shortName;
      }
    },
    getExteriorName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.exteriorName;
        case "en":
          return item.english_exteriorName;
        default:
          return item.exteriorName;
      }
    },
    getRule() {
      this.$axios({
        url: "/api/index/ruletextlist",
        method: "post",
      }).then((res) => {
        if (res.data.code == 1) {
          let data = res.data.data;
          for (let i = 0; i < data.length; i++) {
            if ("追梦".includes(data[i].name)) {
              this.content = data[i].center;
              return;
            }
          }
        }
      });
    },
    goRule() {
      if (document.body.clientWidth > 600) {
        this.tanchuang = true;
      } else {
        this.$router.push({ name: "Rule", params: { title: "追梦" } });
      }
    },
    extractValuesInParentheses(input) {
      const regex = /\((.*?)\)/g;
      const matches = [];
      let match;

      while ((match = regex.exec(input)) !== null) {
        matches.push(match[1]);
      }

      return matches[0];
    },
    getFrist(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[0];
    },
    getLast(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[1];
    },
    splitAndRemoveParentheses(input) {
      if (!input) {
        return [null];
      }
      const parts = input.split("|");
      const result = parts.map((part) => {
        const indexOfParenthesis = part.indexOf("(");
        if (indexOfParenthesis !== -1) {
          return part.substring(0, indexOfParenthesis).trim();
        }
        return part.trim();
      });
      return result;
    },
    getTagAndPz() {},
    onHandledSort() {
      if (this.sortType == "desc") {
        this.sortType = "asc";
      } else if (this.sortType == "asc") {
        this.sortType = "desc";
      }
      this.getList();
    },
    listSort() {
      if (this.priceSort == "desc") {
        this.priceSort = "asc";
      } else if (this.priceSort == "asc") {
        this.priceSort = "desc";
      }
      this.getList();
    },
    //分类
    getListClass() {
      this.$axios.post("/api/lucky/luckytypelist").then((res) => {
        let data = res.data;
        if (data.code == 1) {
          this.selList = data.data;
          for (let i = 0; i < this.selList.length; i++) {
            this.selList[i].state = false;
          }
          this.selList[0].state = true;
        }
      });
    },
    //默认推荐列表
    getList() {
      let param = {
        page: this.page,
        pagesize: this.pageSize,
        sort: this.priceSort,
        // recommend: this.recommend,
      };
      if (this.MinPrice) {
        param["minPrice"] = this.MinPrice;
        param["maxPrice"] = 99999999999;
      }
      // if (this.MaxPrice) {
      //   param["maxPrice"] = this.MaxPrice;
      // }
      if (this.searchKey) {
        param["searchKey"] = this.searchKey;
      }

      if (this.typeList) {
        param["type_id"] = this.typeList.join(",");
      }
      if (this.subClassId && this.subClassId != "all") {
        param["subclass_id"] = this.subClassId;
      }
      if (this.rarity && this.rarity != "不限") {
        param["rarity"] = this.rarity;
      }
      if (this.exterior && this.exterior != "不限") {
        param["exterior"] = this.exterior;
      }

      // if (this.classId == "19") {
      //   param["recommend"] = 1;
      //   delete param.type_id;
      // }

      this.$axios
        .post("/api/lucky/skinlist", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          this.list = data.data.data;
          this.total = data.data.total;
        });
    },
    //点击分类获取列表
    selOran(status, id) {
      console.log(status, id, "000000000000");
      this.recommend = status == 2 ? 1 : 2;
      this.classId = id;
      for (let i = 0; i < this.selList.length; i++) {
        if (id == this.selList[i].id) {
          this.selList[i].state = true;
        } else {
          this.selList[i].state = false;
        }
      }
      this.page = 1;

      if (status == 2 && id == 1) {
        var param = {
          page: this.page,
          pageSize: this.pageSize,
          recommend: 1,
          rarity: this.rarity != "不限" ? this.rarity : "",
          exterior: this.exterior != "不限" ? this.exterior : "",
        };
        var param1 = {
          type_id: "1",
        };
      }
      if (status == 2 && id == 2) {
        var param = {
          page: this.page,
          pageSize: this.pageSize,
          type_id: id,
          rarity: this.rarity != "不限" ? this.rarity : "",
          exterior: this.exterior != "不限" ? this.exterior : "",
        };
        var param1 = {
          type_id: id,
        };
      }
      if (status == 1) {
        var param = {
          page: this.page,
          pageSize: this.pageSize,
          type_id: id,
          rarity: this.rarity != "不限" ? this.rarity : "",
          exterior: this.exterior != "不限" ? this.exterior : "",
        };
        var param1 = {
          type_id: id,
        };
      }

      // console.log(param, param1);

      //列表数据
      // this.$axios
      //   .post("/index/Lucky/skinList", this.$qs.stringify(param))
      //   .then((res) => {
      //     let data = res.data;
      //     //  console.log(res.data);
      //     if (data.status == "1") {
      //       this.list = data.data.list;
      //     } else {
      //       this.list = [];
      //     }
      //   });
      this.getList();
      this.$forceUpdate();
    },
    //点击小分类
    selClass(item) {
      console.log(item);
      this.subClassId = item.id;
      for (let i = 0; i < this.classList.length; i++) {
        if (item.id == this.classList[i].id) {
          this.classList[i].state = true;
        } else {
          this.classList[i].state = false;
        }
      }
      if (item.flag == 0) {
        if (this.classId == 1) {
          var param = {
            page: this.page,
            pageSize: this.pageSize,
            recommend: 1,
          };
        } else {
          var param = {
            page: this.page,
            pageSize: this.pageSize,
            type_id: item.skin_type_id,
          };
        }
      } else {
        if (this.classId == 1) {
          var param = {
            page: this.page,
            pageSize: this.pageSize,
            recommend: 1,
            subclass_id: item.id,
          };
        } else {
          var param = {
            page: this.page,
            pageSize: this.pageSize,
            type_id: item.skin_type_id,
            subclass_id: item.id,
          };
        }
      }

      //console.log(param);
      //列表数据
      // this.$axios
      //   .post("/index/Lucky/skinList", this.$qs.stringify(param))
      //   .then((res) => {
      //     let data = res.data;
      //     if (data.status == "1") {
      //       this.list = data.data.list;
      //     } else {
      //       this.list = [];
      //     }
      //   });
      this.getList();
    },
    goOrnamentOpen(item, type = "add") {
      console.log(item, type, "选中数据");
      if (type == "add") {
        // console.log(item, "选中数据");
        // this.isbot = false;
        // this.getSkinDetail(item.id);
        // this.getList();
        this.obj = item;
        this.price = item.price;
        return;
        // this.wodes = 1;
        // this.getluckyhist1();
      } else if (type == "del") {
        this.obj = [];
      }
    },
    getluckyhist1() {
      let param = {
        page: 1,
        pagesize: this.pageSize,
      };
      this.$axios
        .post("/api/lucky/luckyhistory", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data, "data1");
          var data = res.data;
          if (data.code == "1") {
            // this.total = data.data.list.total;
            this.tableData = data.data;
            // console.log(this.tableData.list.data,'=============')
          }
        });
    },
    //获得历史
    // goHistory() {
    //   this.$router.push({
    //     path: `/OrnamentHistory2`,
    //   });
    // },
    getDeg() {
      var random = this.randomFrom(0, this.value3);
      var random1 = this.randomFrom(this.value3, 100);
      // console.log(this.value3,random, random1,'hahhha')
      if (random >= this.value3) {
        random = this.value3 - 1;
      }
      if (random1 >= 100) {
        random1 = 99;
      }
      // console.log(this.value3,random, random1,'hahhha')
      if (this.winFalse) {
        return 100 * 3.6 * 5 + random * 3.6;
      }
      return 100 * 3.6 * 5 + random1 * 3.6;
    },
    getSquareStyle(angle) {
      var x = this.circleRadius + this.circleRadius * Math.cos(angle);
      var y = this.circleRadius + this.circleRadius * Math.sin(angle);
      if (!this.showNav) {
        x = this.circleRadiuss + this.circleRadiuss * Math.cos(angle);
        y = this.circleRadiuss + this.circleRadiuss * Math.sin(angle);
      }
      const rotation = angle + Math.PI / 2; // 调整方向

      return {
        top: y - 5 + "px",
        left: x - 5 + "px",
        transform: `rotate(${rotation}rad)`,
      };
    },
    randomFrom(lowerValue, upperValue) {
      let result = Math.floor(
        Math.random() * (upperValue - lowerValue) + lowerValue + 1
      );
      return result;
    },
    getSkinDetail(id) {
      let param = {
        skin_id: id,
      };
      this.$axios
        .post("/api/lucky/skin_info", this.$qs.stringify(param))
        .then((res) => {
          const data = res.data;
          if (data.code == 1) {
            if (!data.data) {
              return;
            }
            this.obj = data.data[0];
            // this.obj.back = require("../assets/img/lucky.jpg");
            this.price = this.obj.price;
            this.disruptArr();
            // this.getluckyhist();
          }
        });
    },
    // 是否静音 切换
    ChangeSoundType() {
      if (this.sound.status) {
        this.sound.status = false;
        this.sound.icon = this.sound.minIcon;
      } else {
        this.sound.status = true;
        this.sound.icon = this.sound.maxIcon;
      }
    },
    // 打乱数组
    getRandomArr(arr, num) {
      var _arr = arr.concat();
      var n = _arr.length;
      var result = [];

      // 先打乱数组
      while (n-- && num--) {
        var index = Math.floor(Math.random() * n); // 随机位置
        [_arr[index], _arr[n]] = [_arr[n], _arr[index]]; // 交换数据
        result.push(_arr[n]); // 取出当前最后的值，即刚才交换过来的值
      }
      return result;
    },
    //拖动滑动条 鼠标松开
    changeNum() {
      this.disruptArr();
    },
    //打乱滚动数据
    disruptArr() {
      if (this.loading) {
        return;
      }
      this.openAnimList = [];
      let numImg = parseInt((50 * this.value3) / 100);
      if (numImg < 13) {
        numImg = 13;
      }
      for (let i = 0; i < 50; i++) {
        if (i < numImg) {
          this.openAnimList.push(this.obj);
        } else {
          this.openAnimList.push(this.noWin);
        }
      }
      this.openAnimList = this.getRandomArr(this.openAnimList, 50);
      this.openAnimList[0] = this.obj;
    },
    goOrnament(idnex) {
      this.$router.push({
        path: `/Ornament`,
      });
    },
    //点击兑换
    winexchange() {
      let param = {
        player_skins_ids: [this.list4[0].player_skin_id],
      };
      this.$axios
        .post("/api/rucksack/exchangetomoney", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.code == "1") {
            //改变动画
            this.openAnimState = false;
            this.winState = false;
            this.showanim = true;
            this.$store.commit("getMoney", res.data.data.total_amount);
            Utils.$emit("money", data.data.total_amount);
            this.$message({
              showClose: true,
              message: this.language == "zh" ? data.msg : data.english_msg,
              type: "success",
              customClass: "message-styles",
            });
          }
        });
    },
    //放入背包
    winget() {
      this.showanim = true;
      this.winState = false;
      //改变动画
      this.openAnimState = false;
    },

    //点击升级
    upgrade(event) {
      if (!this.obj) {
        this.$message({
          message: this.$t("请先选择饰品"),
          type: "error",
          customClass: "message-styles",
        });
        return;
      }
      if (this.value3 < 5) {
        this.$message({
          message: this.$t("成功率不能低于5%"),
          type: "error",
          customClass: "message-styles",
        });
        return;
      }
      let _this = this;
      this.showanim = true;
      if (this.sound.status) {
        this.playAlarm(); // 播放BGM
      }
      this.winState = false;
      this.loading = true;
      this.disabled = true;
      let param = {
        skin_id: this.obj.id,
        probability: this.value3,
      };
      this.animState = false;
      this.$axios
        .post("/api/lucky/luckygetskin", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.code == 1) {
            this.openAnimState = true;
            this.list4 = [];
            if (data.data.istrue == 1) {
              this.winFalse = true;
              this.value4 = this.getDeg();
              // this.$parent.getPlayerInfo();
              data.data.back = require("../assets/img/lucky.jpg");
              this.openAnimList[42] = data.data;
              this.$forceUpdate();
              setTimeout(() => {
                this.animState = true;
                this.playAlarm2();
              }, 4000);
              setTimeout(() => {
                this.loading = false;
                this.disabled = false;
                // this.openAnimState = false;
                this.winState = true;
                this.showanim = false;
                // this.list4 = {
                // 	skins_info: [data.data.skins_info],
                // 	// player_skins_ids: [data.data.player_skin_id]
                // 	player_skins_ids: [data.data.player_id]
                // };
                // console.log(this.list4,'list4')
                this.list4 = {
                  skins_info: [
                    {
                      price: data.data["skins_info"]["price"],
                      shortName: data.data["skins_info"]["shortName"],
                      exteriorName: data.data["skins_info"]["exteriorName"],
                      img: data.data["skins_info"]["img"],
                      english_exteriorName:data.data["skins_info"]["english_exteriorName"],
                      english_shortName:data.data["skins_info"]["english_shortName"],
                    },
                  ],
                  // skins_info:data.data.skins_info,
                  player_skins_ids: data.data["skins_info"]["id"],
                };
                // console.log(this.list4,'list4')
                // this.player_skins_ids = [data.data.player_id]
                this.playAlarm3(); // 播放BGM
                // if (this.wodes == 1) {
                //   this.getluckyhist1();
                // } else {
                //   this.getluckyhist();
                // }
                // 关闭BGM
              }, 6780);
              //this.playAlarm2();  // 关闭BGM
            } else {
              this.winFalse = false;
              this.value4 = this.getDeg();
              this.$parent.getPlayerInfo();
              this.openAnimList[42] = this.noWin;
              this.$forceUpdate();
              setTimeout(() => {
                this.animState = true;
                this.playAlarm2();
              }, 4000);
              setTimeout(() => {
                this.$parent.getPlayerInfo();
                this.loading = false;
                this.disabled = false;
                this.openAnimState = false;
                this.list4 = {
                  skins_info: [
                    {
                      price: data.data["skins_info"]["price"],
                      shortName: data.data["skins_info"]["shortName"],
                      exteriorName: data.data["skins_info"]["exteriorName"],
                      img: data.data["skins_info"]["img"],
                      english_exteriorName:data.data["skins_info"]["english_exteriorName"],
                      english_shortName:data.data["skins_info"]["english_shortName"],
                      ccc: false,
                    },
                  ],
                  player_skins_ids: data.data["skins_info"]["id"],
                };

                // this.list4 =  [{
                // 		price: data.data['skins_info']['price'],
                // 		name: data.data['skins_info']['itemName'],
                // 		img: data.data['skins_info']['img'],
                // 		ccc: true
                // 	}]
                // this.player_skins_ids = [data.data.player_id]
                // console.log(this.list4,'list4')
                this.winState = true;
                this.showanim = false;
                this.playAlarm3(); // 播放BGM
                // if (this.wodes == 1) {
                //   this.getluckyhist1();
                // } else {
                //   this.getluckyhist();
                // }
                // 关闭BGM
              }, 6780);
              //this.playAlarm2();  // 关闭BGM
            }
          } else {
            this.playAlarm2();
            this.loading = false;
            this.disabled = false;

            this.$message({
              message: this.language == "zh" ? data.msg : data.english_msg,
              type: "error",
              customClass: "message-styles",
            });
          }
        });
    },
    //子组件修改父组件
    winexchange(msg) {
      this.winState = msg;
    },
    currentchanges(e) {
      this.page = e;
      this.getList();
    },
    currentChange(e) {
      console.log(e, "切换页数");
      this.page = e;
      this.getList();
    },
    winX() {
      //改变动画
      this.openAnimState = false;
      this.winState = false;
      this.showanim = true;
    },
    //音乐 播放
    playAlarm() {
      this.$refs.notify.play();
    },
    //音乐 播放
    playAlarm3() {
      var that = this;
      this.$refs.notifys.play();
      setTimeout(function () {
        that.$refs.notifys.pause();
        that.$refs.notifys.currentTime = 0.0;
      }, 600);
    },
    //音乐 结果暂停
    playAlarm2() {
      this.$refs.notify.pause();
      this.$refs.notify.currentTime = 0.0;
    },
    //获得历史
    goHistory() {
      // console.log('goHistory');
      this.$router.push({
        path: `/OrnamentHistory`,
        query: {
          skin_id: this.obj.id,
        },
      });
    },
    // getluckyhist(page) {
    //   let param = {
    //     // skin_id: this.obj.id,
    //     page: page,
    //     pageSize: this.pageSize,
    //   };
    //   this.$axios
    //     .post("/api/lucky/userhistory", this.$qs.stringify(param))
    //     .then((res) => {
    //       //  console.log(res.data,"后面数据");
    //       var data = res.data;
    //       if (data.code == "1") {
    //         this.wodes = 2;
    //         // this.tableData = data.data.list;
    //         this.tableData = data.data;
    //         // console.log(this.tableData,'列表数据')
    //       }
    //     });
    // },
    // //分页 所有记录
    // currentChange(val) {
    //   this.getluckyhist(val);
    // },

    goLucky() {
      this.$router.push({
        path: `/Ornament`,
      });
    },
    showMobileFilters() {
      this.mobileFilters = true;
      this.typeList = this.mobileTypeList;
    },
    closeMobileFilters() {
      this.mobileFilters = false;
    },
    clearFilters() {
      this.typeList = [];
      this.mobileTypeList = [];
      this.page = 0;
      this.closeMobileFilters();
    },
    saveMobileFilters() {
      this.typeList = this.mobileTypeList;
      this.mobileFilters = false;
      this.page = 1;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.win-box {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
}

.bos3 {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 99999;
  animation: sss 0.5s linear;
  animation-fill-mode: both;

  @keyframes sss {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 150px;
    height: 50px;
  }

  div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 32px;
    color: white;
    font-family: "微软雅黑";

    span:last-child {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

/deep/.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

/deep/.el-pagination .btn-prev,
.btn-next {
  background: none !important;
}

/deep/.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: none !important;
}

/deep/.el-pager li.active {
  background: none;
  color: white;
  background-color: #337e0d !important;
  border-radius: 50%;
  min-width: 28px !important;
  font-family: "微软雅黑";
}

/deep/.el-pager li {
  background: none;
  color: white;
  min-width: 28px !important;
  font-family: "微软雅黑";
}

/deep/.el-pagination button:disabled {
  background: none;
}

@keyframes luan {
  10% {
    transform: rotate(10deg) scale(0);
  }

  10% {
    transform: rotate(45deg) scale(0.5);
  }

  20% {
    transform: rotate(90deg) scale(0);
  }

  30% {
    transform: rotate(125deg) scale(0.6);
  }

  40% {
    transform: rotate(160deg) scale(0);
  }

  50% {
    transform: rotate(185deg) scale(0.8);
  }

  60% {
    transform: rotate(220deg) scale(0);
  }

  70% {
    transform: rotate(255deg) scale(0.4);
  }

  80% {
    transform: rotate(290deg) scale(0);
  }

  0% {
    transform: rotate(300deg) scale(0.3);
  }

  90% {
    transform: rotate(330deg) scale(0);
  }

  100% {
    transform: rotate(1turn) scale(1);
  }
}
.luckySkins {
  padding: 4rem 1.5rem;
  box-sizing: border-box;
  background: #1e2336;
}
.luckySkins .luckySkins-title {
  text-align: center;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
}
.upgrade__body {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.upgrade__body .upgrade__selected-container {
  display: flex;
  height: 18rem;
  flex-direction: column;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
  width: calc(50% - 16.25rem);
  background-image: radial-gradient(circle at center, #131723 0, #181d2c 100%);
}
.upgrade__body .upgrade__selected-container--left {
  border-radius: 0.5rem;
  padding-left: 2rem;
  order: 1;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding-right: 4rem;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.text-gray-100 {
  color: rgb(234 234 237);
}
.font-medium {
  font-weight: 500;
}
.text-sbase {
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.text-gray-500 {
  color: rgb(127 130 145);
}
.text-ssm {
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.upgrade__circle-wrapper {
  margin-top: 4rem;
  margin-bottom: 6rem;
  display: flex;
  justify-content: center;
  order: 2;
  border-radius: 0;
  width: 20.5rem;
  color: #fff;
  background-image: radial-gradient(circle at center, #131723 0, #181d2c 100%);
}
.upgrade__circle {
  position: relative;
  z-index: 10;
  margin: -3rem;
  display: grid;
  height: 27rem;
  width: 27rem;
  place-items: center;
  // border: 1.5rem solid rgb(30 35 54 );
  border-width: 1.5rem;
  border-radius: 9999px;
  border-color: rgb(30 35 54);
  background-color: rgb(30 35 54);
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
  //   var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.upgrade__circle-display {
  position: relative;
  margin: -3rem;
  border: 2px solid rgb(61 90 254);
  border-radius: 50%;
  // border-width: 2px;
  // border-color: rgb(61 90 254 );
  padding: 1.25rem;
  // --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  // --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
  //   var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 19.5rem;
  width: 19.5rem;
  background: #131723;
}
.upgrade__circle-indicator {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  transition-property: transform;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  // transition-duration: 0.15s;
  // transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  // transition-duration: 4s;
  // transform: rotate(calc(var(--circle-result-percent) / 100 * 360deg));
  transform: rotate(var(--circle-result-percent));
}
.upgrade__circle_start {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 4s;
  transform: rotate(var(--bg-qudus));
}
.upgrade__circle-needle-wrapper {
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
}
.upgrade__circle-needle {
  width: 0;
  height: 0;
  border-left: 0.625rem solid transparent !important;
  border-right: 0.625rem solid transparent !important;
  border-top: 1rem solid;
  border-color: rgb(61 90 254);
  transform: rotate(var(--bg-qudu));
}
.upgrade__chance-fill {
  position: absolute;
  inset: 0;
  z-index: -10;
  display: none;
  height: 100%;
  width: 100%;
  border-radius: 9999px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  background: radial-gradient(
    circle at 50% 0%,
    var(--chance-subtle-fill-color) 25%,
    var(--chance-fill-color) 100%
  );
}
.upgrade__circle-inner {
  position: relative;
  display: grid;
  height: 100%;
  width: 100%;
  place-items: center;
  border-radius: 9999px;
}
.upgrade__circle-inner-border {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  border-radius: 9999px;
  border-width: 2px;
  border-style: dashed;
  border-color: rgb(48 54 75);
  transform: rotate(
    calc(3600deg - var(--circle-result-percent) / 100 * 360deg)
  );
}
.upgrade__chance-pie {
  position: absolute;
  inset: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  border-radius: 9999px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  --chance-pie-percent: 0%;
  --chance-pie-color: rgba(61, 90, 254, 50%);
  --chance-subtle-pie-color: rgba(61, 90, 254, 25%);
  --chance-pie-more-percent: 0%;
  --chance-pie-more-color: rgba(238, 73, 70, 80%);
  --chance-subtle-pie-more-color: rgba(238, 73, 70, 50%);
  background: conic-gradient(
    var(--chance-pie-color) 0%,
    var(--chance-subtle-pie-color) var(--chance-pie-percent),
    var(--chance-subtle-pie-more-color) var(--chance-pie-percent),
    var(--chance-pie-more-color)
      calc(var(--chance-pie-percent) + var(--chance-pie-more-percent)),
    transparent calc(var(--chance-pie-percent) + var(--chance-pie-more-percent))
  );
}
.upgrade__circle-text {
  position: absolute;
  inset: 0;
  display: grid;
  height: 100%;
  width: 100%;
  place-items: center;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.upgrade__chance {
  padding: 1.5rem;
  text-align: center;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.upgrade__selected-container--right {
  order: 3;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding-left: 4rem;
  padding-right: 2rem;
  border-radius: 0.5rem;
}
.upgrade__use-balance {
  width: 40rem;
  margin: 0 auto;
  color: #fff;
}
.goAdd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  span {
    display: block;
    background-image: linear-gradient(
      90deg,
      transparent,
      rgba(61, 90, 254, 25%)
    );
    border: 2px solid #3d5afe;
    letter-spacing: 0.025em;
    border-radius: 99vw;
    margin-bottom: 2rem;
    padding: 0.625rem 3.5rem;
    letter-spacing: 0.025em;
    color: #fff;
    &:hover {
      cursor: pointer;
      background-color: #3d5afe;
    }
  }
}
.goAdd.disabled {
  opacity: 0.5;
}
.boxlist {
  width: 100%;
  min-height: 740px;
  background-color: #292f47;
  border-radius: 7px;
  margin-top: 20px;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  .title {
    font-size: 1.125rem;
    line-height: 3rem;
    font-weight: 500;
    color: #eaeaed;
    height: 3rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    span {
      color: #9497a3;
    }
  }

  .pagination {
    // position: absolute;
    // left: 50%;
    // bottom: 40px;
    // transform: translate(-50%, 0);
  }
  .active {
    border: 1px solid #f5d016;
  }
  .boxs {
    width: 100%;
    display: flex;
    // justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
  }
  .box {
    margin: 0.37vw;
    // width: 9.3vw;
    // height: 9.3vw;
    width: calc(100% / 7 - 0.75vw);
    // height: 180px;
    height: 13.5vw;
    // height: var(--ww);
    // background-image: url(./img/boxlist-bg.png);
    // background-size: 125% auto;
    // background-position: center;

    // border-radius: 10px;
    background: #131723cc;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-sizing: border-box;
    cursor: pointer;
    border-top: 2px solid #fff;
    // flex-direction: column;
    padding: 1.25rem;
    box-sizing: border-box;
    .price {
      position: absolute;
      top: 5px;
      right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 15px;
        height: auto;
      }
    }
    .wuqi {
      img {
        width: 140px;
        height: auto;
      }
    }
    .description {
      width: 80%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      position: absolute;
      // left: 5px;
      bottom: 0.6rem;
      .c1 {
        color: #a9abbc;
        font-size: 12px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .c2 {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
.transition-opacity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #131723cc;

  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 68px;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 0.2s;
  }
}
.transition-opacity.add {
  opacity: 0;
  img {
    width: 50px;
  }
}
.boxlist .boxs .box:hover {
  .transition-opacity.add {
    cursor: pointer;
    opacity: 1;
    img {
      width: 70px;
    }
  }
}
.no-login {
  width: 100%;
  color: #7f8291;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  p {
    margin: 1.6rem 0;
  }
  .steam-login {
    display: flex;
    color: #fff;
    text-decoration: none;
    padding: 0.625rem 1.5rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.1s;
    border: 2px solid #27ae60;
    border-radius: 99px;
    align-items: center;
    background: linear-gradient(
      to right,
      rgba(39, 174, 96, 0) 0%,
      rgba(39, 174, 96, 0.3) 100%
    );
    svg {
      font-size: 1.25em;
      line-height: 0.05em;
      width: 1.2rem;
      margin: 0rem 0.4rem;
    }
  }
}
.upgrade__add-item {
  filter: brightness(1.25) saturate(1.25);
  max-height: 14rem;
  height: 14rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  // width: 14rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    .close {
      opacity: 1;
      scale: 1.2;
      .close-icon {
        scale: 1.2;
      }
    }
  }
  .close {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #10111acc;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    z-index: 20;
    padding: 0.5rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.2s;
    opacity: 0;
    // opacity: 0.8;
    .close-icon {
      width: 2rem;
      height: 2rem;
      opacity: 0.8;
    }
  }
  img {
    position: relative;
    height: 100%;
    // width: 100%;
    z-index: 10;
  }
  .absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10rem;
    height: 10rem;
  }
}
.upgrade__selected-info {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.upgrade__selected-text {
  text-align: left;
}
.upgrade__selected-amount p {
  width: fit-content;
  min-width: 4rem;
}
.upgrade__selected-amount {
  margin-right: -5rem;
  padding-right: 4rem;
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  text-align: center;
  display: flex;
  height: 3rem;
  align-items: center;
  background-color: rgb(30 35 54);
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-gray-400 {
  color: rgb(148 151 163);
}
.text-white {
  color: rgb(255 255 255);
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.justify-between {
  justify-content: space-between;
}

.search-box {
  width: 50%;
  text-align: right;
}
.search-box .price {
  width: 125px;
  height: 50px;
  margin-right: 8px;
}
.search-box .price /deep/.el-input__inner {
  width: 100%;
  height: 100%;
}
.search-box /deep/.el-button {
  width: 100%;
  height: 100%;
  background: #131723;
  border: none;
  color: #606266;
  padding: 0;
  line-height: 50px;
}
.search-box /deep/.el-button span {
  display: block;
  width: 100%;
  height: 100%;
}
.roll-price {
  display: flex;
  justify-content: flex-end;
  .sort-block {
    background-color: #131723;
    border-radius: 5px;
    width: 85px;
    height: 50px;
    display: flex;
    align-items: center;
    color: #606266;
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    // margin-right: 10px;
    cursor: pointer;
    user-select: none;

    .sort-icon {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      img {
        width: 20px;
      }
      img.desc {
        transform: rotate(180deg);
      }

      .icon {
        color: #333;
        font-size: 10px !important;
      }

      .active-icon {
        color: #fff !important;
      }
    }
  }

  .left {
    flex: 1;
    // min-width: 200px;
    min-height: 60px;

    .shengjiang {
      padding: 8px 14px;
      border-radius: 4px;
      background-color: #e9b10e;
      display: flex;
      align-items: center;

      span {
        margin-right: 6px;
      }

      img {
        width: 14px;
      }
    }

    .pz-container,
    .wg-container {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 5px 0;
      width: 100%;

      .name {
        color: #fff;
        width: 45px;
      }

      .wg-content {
        color: #848492;
        // flex: 1;
        white-space: nowrap;
        overflow: auto;

        span {
          padding: 0 10px;
          cursor: pointer;

          &:hover {
            color: #e9b10e;
          }
        }

        .active {
          color: #e9b10e;
        }
      }
    }
  }

  .right {
    max-width: 400px;
    display: flex;
    align-items: center;
    // margin-left: 10px;

    span {
      margin-left: 5px;
      margin-right: 5px;
      color: #e9b10e;
    }

    .screen {
      height: 40px;
      line-height: 40px;
      margin-left: 20px;
      background-color: #e9b10e;
      padding: 0 30px;
      font-weight: 600;
      color: #1a1c24;
      border-radius: 5px;
    }

    .screen:hover {
      cursor: pointer;
    }

    .input {
      width: 100px;

      img {
        width: auto;
        height: 15px;
      }
    }

    .input /deep/ .el-input__prefix,
    .el-input__suffix {
      top: 12.5px;
    }

    .input /deep/ .el-input__inner {
      background-color: #000;
      color: white;
      border: 1px solid #893e8a;
    }
  }
}
.input1 {
  width: 200px;
}

.input1-i:hover {
  cursor: pointer;
}
.search-box /deep/.el-input__inner {
  background-color: #131723;
  border: none !important;
  color: #c3c3e2;
  width: 255px;
  height: 50px;
  margin-left: 4px;
  padding: 17px 24px 17px 35px;
}
.search-box .type-button {
  position: relative;
}
.search-box .h-4 {
  height: 20px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.search-box .type-down {
  height: 50px;
  width: 50px;
  box-sizing: border-box;
  border-radius: 0.25rem;
  border-width: 0;
  background-color: rgb(19 23 35);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: rgb(234 234 237);
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}
.text-gray-500 {
  color: rgb(127 130 145);
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.empty {
  text-align: center;
  width: 100%;
  margin-top: 100px;
  color: #fff;
}
.type-down {
  position: relative;
  cursor: pointer;
  display: inline-block;
  // padding: 17px 5px 17px 20px;
  width: 140px;
  height: 50px;
  margin: 0px 8px;
}
.type-down .dropdown {
  position: absolute;
  top: 52px;
  left: -255%;
  // transform: translateX(-50%);
  width: 155px;
  background: #131723;
  border-radius: 4px;
  transition: all 0.5s;
  padding: 12px;
  z-index: 99;
}
.type-down .dropdown .el-checkbox {
  width: 100%;
  padding: 12px 10px;
  margin-right: 0px !important;
  box-sizing: border-box;
  display: flex;
}
.type-down .dropdown /deep/.el-checkbox__input {
  position: absolute;
  right: 0;
}
.type-down .dropdown /deep/.el-checkbox__inner {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.type-down
  .dropdown
  /deep/.el-checkbox__input.is-checked
  + .el-checkbox__label {
  color: #fff !important;
}
/deep/.el-checkbox__inner {
  background-color: transparent !important;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #3d5afe !important;
}
/deep/.el-checkbox__inner::after {
  content: "✔";
  color: #fff;
  border: none !important;

  left: 0px !important;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(0deg) scaleY(1) !important;
}
/deep/.el-slider__bar {
  background-color: rgb(61 90 254);
}
/deep/.el-slider__button {
  width: 10px;
  height: 10px;
  border: 2px solid rgb(61 90 254);
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-ring-offset-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-shadow: 0 0 10px 0.25rem rgba(61, 90, 254, 0.5);
}
@media (max-width: 764px) {
  .upgrade__circle {
    background-color: transparent !important;
  }
  .upgrade__selected-container--left {
    order: 4 !important;
  }
  .upgrade__circle-wrapper {
    order: 1 !important;
    background-image: none !important;
    margin-bottom: 0rem !important;
  }
  .upgrade__selected-container--right {
    order: 5 !important;
  }
  .upgrade__use-balance {
    order: 2;
    width: 20rem !important;
    /deep/.el-slider__runway {
      background-color: #131723;
      margin: 16px auto;
    }
  }
  .upgrade__selected-container--left,
  .upgrade__selected-container--right {
    width: 100% !important;
    padding: 2rem !important;
    margin: 0.5rem auto !important;
  }
  .goAdd {
    order: 3;
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    span {
      width: 100%;
      text-align: center;
    }
  }
  .upgrade__circle-display {
    width: 17.5rem !important;
    height: 17.5rem !important;
  }
  .boxlist .title {
    flex-direction: column;
  }
  .roll-price {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .boxlist .search-box {
    width: 100%;
    .input1 {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 0.4rem;
      margin-left: 0 !important;
      margin-right: 0 !important;
      order: 1;
    }
    .sort-block {
      order: 3;
      margin-right: 4px;
    }
    .price {
      order: 2;
    }
    .filter-button {
      order: 4;
    }

    /deep/.el-input__inner {
      width: 100% !important;
      margin-left: 0;
    }
    .filter-button {
      display: inline-block;
      width: 80px;
      height: 50px;
      line-height: 50px;
      // margin: 12px 0 0 12px;
      padding-left: 10px;
      text-align: left;
      background: url("../assets/img/filter-settings.svg") 80% 50% no-repeat
        #131723;
      position: relative;
      color: #fff;
      box-sizing: border-box;
      font-size: 14px;
      border-radius: 5px;
    }
  }
  .box {
    width: calc(100% / 2 - 4vw) !important;
    height: 53.5vw !important;
    margin: 2vw !important;
  }
  .title {
    margin-bottom: 0rem !important;
    padding-left: 2vw !important;
    padding-right: 2vw !important;
    height: auto !important;
  }
  .boxlist {
    padding: 4vw 1vw;
  }
}
.mobile-skins-filter {
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #131723;
  color: #fff;
  .mobile-filters-header {
    border-bottom: 1px solid #343a4a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 5%;
  }
  .close {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    span {
      margin-left: 20px;
    }
  }
  .clear-button {
    background-color: #ffffff0d;
    margin-top: 0;
    padding: 17px;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
  }
}
.mobile-filters-body {
  span {
    padding: 30px 0 0 20px;
    color: #fff;
    display: block;
    width: 100%;
  }
  padding: 0 5% 120px;
  /deep/.el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  /deep/.el-checkbox {
    padding: 12px 10px;
    // opacity: .5;
    margin-right: 0px !important;
    padding-left: 20px;
    position: relative;
  }
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: #fff;
  }
  .color-dropdown /deep/.el-checkbox__label:before {
    right: 0px !important;
    z-index: 1;
    left: auto !important;
    height: 70% !important;
  }
  .color-dropdown /deep/.el-checkbox__label {
    position: static;
  }
}
.mobile-filters-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 23px;
  background: #131723;
  border-top: 1px solid #343a4a;
  z-index: 2;
  .mobile-filters-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
    background: linear-gradient(
      270deg,
      rgba(61, 90, 254, 0.3) 0%,
      rgba(61, 90, 254, 0) 100%
    );
    border: 2px solid #3d5afe;
    border-radius: 999px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
  }
}
.mobile-search {
  padding: 0 10px;
}

</style>