<template>
  <div class="arena">
    <div class="arena-warp">
      <!-- 面包屑导航 -->
      <div class="openbox-container_header breadcrumb">
        <div class="cat-breadcrumb" @click="$router.go(-1)">CSGOOP</div>
        <div class="ng-binding">
          <img src="../assets/img/chevron-down-1.svg" alt="" />
          {{ $t("ROLL房") }}
        </div>
      </div>
      <!-- Roll房类型 -->
      <div class="main-cese-button">
        <div class="item-box type">
          <div
            class="item"
            :class="{ active: nav1 == 1 }"
            @click="changeNav1(1)"
          >
            {{ $t("官方") }}
          </div>
          <div
            class="item"
            :class="{ active: nav1 == 2 }"
            @click="changeNav1(2)"
          >
            {{ $t("主播") }}
          </div>
          <div
            class="item"
            :class="{ active: nav1 == 3 }"
            @click="changeNav1(3)"
          >
            {{ $t("我参与的") }}
          </div>
        </div>
      </div>
      <div class="main-cese-button">
        <div class="item-box">
          <div
            class="item"
            :class="{ 'case-active': nav2 == 1 }"
            @click="changeNav2(1)"
          >
            {{ $t("进行中") }}
          </div>
          <div
            class="item"
            :class="{ 'case-active': nav2 == 2 }"
            @click="changeNav2(2)"
          >
            {{ $t("已结束") }}
          </div>
        </div>
      </div>
      <div class="room" style="color: white">
        <div class="item" v-for="(item, index) in list" :key="index">
			<div class="item-border"></div>
          <div class="top">
            <div class="row1">
              <div class="r1">{{ getRoomName(item) }}</div>
              <div class="time">{{ $t("截止时间") }}：{{ item.endtime }}</div>
            </div>
            <div class="avatar">
              <img :src="item.image" alt="" class="img" />
              <!-- <img
                src="./img/avatar-bottom-guan.png"
                alt=""
                class="bo"
                v-if="item.type == 1"
              />
              <img src="./img/avatar-bottom-zhu.png" alt="" class="bo" v-else /> -->
            </div>
          </div>
          <!-- <div class="info">
            <div class="left">
              <span class="s1">总价值</span>
              <span class="s2">{{ item.pool ? item.pool : 0 }}</span>
            </div>
            <div class="center">
              <span class="s1">物品</span>
              <span class="s2">{{ item.count ? item.count : 0 }}</span>
            </div>
            <div class="left">
              <span class="s1">玩家</span>
              <span class="s2">{{
                item.person_num ? item.person_num : 0
              }}</span>
            </div>
          </div> -->
          <div class="wuqi">
            <template v-for="(item2, index) in item.skin_list">
              <template v-if="index < 2 && item2">
                <div class="left" :key="index">
                  <div class="border"></div>
                  <img :src="item2.imageUrl" alt="" />
                  <div class="wuqi-info">
                    <div class="name">
                      {{ getName(item2) }}
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <!-- <div class="right">
              <div class="top" v-if="item.skin_list[1]">
                <img :src="item.skin_list[1].imageUrl" alt="" />
                <span class="money">
                  <img
                    src="@/assets/img/money.png"
                    alt=""
                    style="width: 16px"
                  />
                  <span>{{ item.skin_list[1].price }}</span>
                </span>
              </div>
            </div> -->
          </div>
          <div class="bottoms" @click="jionRoom(item.id)">
            <!-- <img src="./img/chafang.png" alt="" /> -->
            <span>{{ $t("查看房间") }}<svg
            data-v-eebb099b=""
            class="w-4.5 h-4.5"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              data-v-eebb099b=""
              d="M5.99316 3.48657H13.531V11.0421"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              data-v-eebb099b=""
              d="M13.531 3.48657L3.46875 13.5488"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path></svg
        ></span>
          </div>
        </div>
        <!-- <div class="item"></div>
				<div class="item"></div>
				<div class="item"></div> -->
      </div>
      <div class="roll-page" style="margin-bottom: 50px">
		<pagination
              v-if="total > 0"
              :total="total"
              :currentPage="page"
              :perPage="pageSize"
              :showNav="showNav"
              @current-change="currentChange"
            ></pagination>
        <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          @current-change="currentChange"
        >
        </el-pagination> -->
      </div>
    </div>
    <div class="clear"></div>
    <myhomebot></myhomebot>
    <!-- <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader> -->
  </div>
</template>
<script>
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import navheader from "@/components/navheader.vue";
import pagination from "@/components/pagination.vue";
export default {
  components: {
    myhomebot,
    myinform,
    navheader,
	pagination
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      nav1: 1,
      nav2: 1,
      activeName: "first",
      list: [],
      list1: [],
      payType: "0",
      total: 0,
      page: 1,
      type: 1,
      status: 1,
    };
  },
  async mounted() {
    await this.getMyList();
    this.getList();
  },
  methods: {
	getName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.itemName;
        case "en":
          return item.marketHashName;
        default:
          return item.itemName;
      }
    },
	getRoomName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.room_name;
        case "en":
          return item.english_room_name;
        default:
          return item.room_name;
      }
    },
    // 点击上方的切换按钮
    changeNav1(index) {
      this.nav1 = index;
      this.type = index;
      this.getList();
    },
    // 点击下方的切换按钮
    changeNav2(index) {
      this.nav2 = index;
      this.status = index;
      this.getList();
    },
    // 点击页码切换数据
    currentChange(index) {
      this.page = index;
      this.getList();
    },
    // 获取数据
    getList() {
      let data = {
        page: this.page,
        pagesize: 12,
        type: this.type,
        status: this.status,
      };
      this.$axios({
        url: "/api/free/freeroomList",
        method: "post",
        data: data,
      }).then((res) => {
        if (res.data.code == 1) {
          let datas = res.data.data.data;
          // 拿到数据修改时间格式
          for (let i = 0; i < datas.length; i++) {
            let endtime = "";
            let time = datas[i].run_lottery_time;
            endtime += time.substring(0, 10);
            endtime.replace(/-/g, "/");
            // console.log(endtime,'timeeeee')
            datas[i].endtime = endtime;
          }

          this.list = res.data.data.data;
          this.total = res.data.data.total;
        }
      });
    },

    handleClick(tab, event) {
      if (tab.name == "first") {
        this.getList();
      } else {
        this.getMyList();
      }
    },
    //免费皮肤房间列表
    getLists() {
      return;
      let _this = this;
      let param = {
        page: 1,
        pagesize: 50,
        type: "",
      };
      _this.$axios
        .post("/api/free/freeroomList", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.code == 1) {
            this.list = data.data.data;
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].person_num == null) {
                this.list[i].person_num = 0;
              }
              if (this.list[i].count == null) {
                this.list[i].count = 0;
              }
              if (this.list[i].pool == null) {
                this.list[i].pool = 0;
              }
            }
            this.list.forEach((e) => {
              this.list1.forEach((es) => {
                if (e.id == es.id && e.status != 2) {
                  e.status = 3;
                }
              });
            });
          }
        });
    },
    // 官方roll
    getTypeList(e) {
      return;
      let _this = this;
      let param = {
        page: 1,
        pageSize: 30,
        type: 1,
      };
      this.list = [];
      this.list1 = [];
      this.payType = e;
      if (e == 0) {
        this.getList();
      }
      if (e == 1) {
        _this.$axios
          .post("/api/free/freeroomList", _this.$qs.stringify(param))
          .then((res) => {
            // console.log(res.data);
            var data = res.data;
            if (data.code == 1) {
              this.list = data.data.data;
              for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].person_num == null) {
                  this.list[i].person_num = 0;
                }
                if (this.list[i].count == null) {
                  this.list[i].count = 0;
                }
                if (this.list[i].pool == null) {
                  this.list[i].pool = 0;
                }
              }
              this.list.forEach((e) => {
                this.list1.forEach((es) => {
                  if (e.id == es.id && e.status != 2) {
                    e.status = 3;
                  }
                });
              });
            }
          });
      }
      if (e == 2) {
        param = {
          page: 1,
          pageSize: 30,
          type: 2,
        };
        _this.$axios
          .post("/api/free/freeroomList", _this.$qs.stringify(param))
          .then((res) => {
            // console.log(res.data);
            var data = res.data;
            if (data.code == 1) {
              this.list = data.data.data;
              for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].person_num == null) {
                  this.list[i].person_num = 0;
                }
                if (this.list[i].count == null) {
                  this.list[i].count = 0;
                }
                if (this.list[i].pool == null) {
                  this.list[i].pool = 0;
                }
              }
              this.list.forEach((e) => {
                this.list1.forEach((es) => {
                  if (e.id == es.id && e.status != 2) {
                    e.status = 3;
                  }
                });
              });
            }
          });
      }
      if (e == 3) {
        param = {
          page: 1,
          pageSize: 30,
          type: 4,
        };
        _this.$axios
          .post("/api/free/freeroomList", _this.$qs.stringify(param))
          .then((res) => {
            // console.log(res.data);
            var data = res.data;
            if (data.code == 1) {
              this.list = data.data.data;
              for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].person_num == null) {
                  this.list[i].person_num = 0;
                }
                if (this.list[i].count == null) {
                  this.list[i].count = 0;
                }
                if (this.list[i].pool == null) {
                  this.list[i].pool = 0;
                }
              }
              this.list.forEach((e) => {
                this.list1.forEach((es) => {
                  if (e.id == es.id && e.status != 2) {
                    e.status = 3;
                  }
                });
              });
            }
          });
      }
      if (e == 4) {
        this.getMyList();
      }
      this.$forceUpdate();
    },
    //我参与的房间列表
    getMyList() {
      return;
      let _this = this;
      let param = {
        page: 1,
        pageSize: 10,
        type: 3,
      };
      _this.$axios
        .post("/api/free/freeroomList", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.code == 1) {
            this.list1 = data.data.data;
            for (let i = 0; i < this.list1.length; i++) {
              if (this.list1[i].person_num == null) {
                this.list1[i].person_num = 0;
              }
              if (this.list1[i].count == null) {
                this.list1[i].count = 0;
              }
              if (this.list1[i].pool == null) {
                this.list1[i].pool = 0;
              }
            }
          }
        });
    },
    //加入房间
    jionRoom(id, status) {
      // if (!this.$store.state.token) {
      //   this.$store.commit("getLogin", true);
      //   return;
      // }
      this.$router.push({
        path: `/ArenaRoom`,
        query: {
          id: id,
          status: status,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.boxsss {
  width: 500px;
  padding: 0 32px;
  border-radius: 32px;
  border: 1px solid rgb(30, 28, 44);
  padding: 2px;
  margin-top: 32px;
}

@media screen and (max-width: 767px) {
  .boxsss {
    width: 80%;
    min-width: 280px;
    border-radius: 32px;
    border: 1px solid rgb(30, 28, 44);
    padding: 2px;
    margin: 12px auto;
  }
  .extension_index_img {
    display: none !important;
    // padding: 16px 50px;
    img {
      width: 80%;
      height: auto !important;
    }
    margin: auto !important;
  }

  .el-btn {
    width: 20% !important;
    padding: 7px 0 !important;
    border-radius: 32px;
    border: none;
    // border: 1px solid rgb(233, 177, 14);
    background: none;
    // color: rgb(233, 177, 14);
    color: white;
    font-weight: 400 !important;
    font-size: 10px !important;
    // font-weight: 600;
  }

  // .el-btn:hover {
  // 	width: 100px;
  // 	padding: 8px 0;
  // 	border-radius: 32px;
  // 	// border: 1px solid rgb(233, 177, 14);
  // 	background: rgb(233, 177, 14);
  // 	color: white;
  // 	// font-weight: 600;
  // }

  .el-btn-click {
    width: 20% !important;
    padding: 7px 0 !important;
    border-radius: 32px;
    // border: 1px solid rgb(233, 177, 14);
    border: none;
    background: rgb(233, 177, 14);
    color: white;
    // font-weight: 600;
    font-weight: 400 !important;
    font-size: 10px !important;
  }

  .list-warp {
    margin: 8px 0 !important;
    // padding: 16px;
    background-color: #2b2a37;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
}

.el-btn {
  width: 100px;
  padding: 8px 0;
  border-radius: 32px;
  border: none;
  // border: 1px solid rgb(233, 177, 14);
  background: none;
  // color: rgb(233, 177, 14);
  color: white;
  font-weight: 600;
  // font-weight: 600;
}

// .el-btn:hover {
// 	width: 100px;
// 	padding: 8px 0;
// 	border-radius: 32px;
// 	// border: 1px solid rgb(233, 177, 14);
// 	background: rgb(233, 177, 14);
// 	color: white;
// 	// font-weight: 600;
// }

.el-btn-click {
  width: 100px;
  padding: 8px 0;
  border-radius: 32px;
  // border: 1px solid rgb(233, 177, 14);
  border: none;
  background: rgb(233, 177, 14);
  color: white;
  // font-weight: 600;
  font-weight: 600;
}

.arena {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #1e2335;

  .arena-warp {
    min-height: 100vh;
    padding: 40px 56px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    @media (max-width: 550px) {
		padding: 2rem 1.5rem;
    }

    .extension_index_img {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 100px;
      img {
        height: 100px;
      }
    }

    // display: flex;
    /deep/.el-tabs__header {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /deep/.el-tabs__nav-wrap {
      // width: 200px;
    }

    .tabs /deep/ .el-tabs__item.is-active {
      color: #c3c3e2;
    }

    .tabs /deep/ .el-tabs__item {
      color: #848492;
      font-size: 20px;
    }

    .tabs /deep/ .el-tabs__nav-wrap::after {
      background-color: #1a1c24;
    }

    .tabs {
      .list {
        margin: 0 0;
        width: 100%;

        @media screen and (max-width: 767px) {
          ul {
            // background-size: cover !important;
            width: 80% !important;
            background: none !important;
            padding: 0 !important;
            min-width: 280px;
          }
        }

        ul {
          margin: 0 0;
          width: 100%;
          margin: auto;
          padding: 32px 0;
          // background-color: rgb(16, 21, 27);
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          // height: 1000px;

          li {
            width: 19%;

            .list-warp {
              margin: 8px;
              // padding: 16px;
              background-color: #2b2a37;
              border-radius: 5px;
              overflow: hidden;
              position: relative;

              .tou-warp {
                width: 100%;
                height: 130px;
                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }

                .tou-warp-back {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 130px;
                  background: linear-gradient(
                    360deg,
                    rgba(43, 44, 55, 1) 0%,
                    rgba(43, 44, 55, 0.5) 100%
                  );
                }
              }

              .back1 {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                left: 0;
              }

              .tou {
                padding: 0 16px;
                margin-top: -115px;
                position: relative;
                z-index: 66;
                display: flex;
                justify-content: center;

                img {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  border: 1px solid #ae23c6;
                }

                span {
                  color: #fff;
                  font-size: 12px;
                  padding: 0 6px;
                  position: absolute;
                  bottom: -3px;
                  background-color: #ae23c6;
                  border-radius: 3px;
                }
              }

              .name {
                position: relative;
                z-index: 66;
                padding: 0 16px;
                margin-top: 5px;
                font-size: 16px;
                color: #c3c3e2;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .num {
                position: relative;
                z-index: 66;
                padding: 0 16px;
                margin-top: 10px;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .num-box {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  span {
                    color: #848492;
                  }

                  span:last-child {
                    margin-top: 5px;
                  }

                  .num-span {
                    color: #e9b10e;
                  }
                }
              }

              .img {
                padding: 0 16px;
                margin-top: 20px;

                .img-box {
                  width: 33.33%;
                  float: left;
                  background-image: url("../assets/img/beibao.png");
                  background-size: 100% 100%;
                  position: relative;
                  text-align: center;
                  // margin-right: 2px;

                  img {
                    width: 70%;
                    height: 45px;
                  }

                  span {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    // margin-left: -25px;
                    color: #fff;
                    padding: 0 10px;
                    border-radius: 20px;
                    background-color: rgba(0, 0, 0, 0.3);
                    font-size: 10px;
                  }
                }

                .img-box:first-child {
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                }

                .img-box:last-child {
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
              }

              .btn {
                padding: 0 16px;
                margin-top: 20px;
                display: flex;
                justify-content: center;

                .btn-warp {
                  display: flex;
                  align-items: center;
                  background-color: #e9b10e;
                  padding: 5px 25px;
                  border-radius: 20px;

                  img {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                  }

                  span {
                    font-size: 14px;
                    color: black;
                  }
                }

                .btn-warp:hover {
                  // cursor: pointer;
                  // border: 1px solid#e9b10e;
                  background-color: #ce9a0c;
                }

                // .btn-warp:hover span {
                // 	color: #e9b10e;
                // }
              }

              .time {
                padding: 0 16px;
                margin: 20px 0 16px 0;

                text-align: center;
                font-size: 10px;
                color: #848492;
              }
            }
          }
        }
      }
    }
  }
}

.navList1 {
  width: 100%;
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: white;
  display: flex;
  @media (max-width: 550px) {
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
  }
  .span {
    span {
      width: 100%;
      background-color: rgb(18, 22, 31);
      display: block;
    }
    flex: 1;
    height: 55px;
    font-weight: 700;
    cursor: pointer;
    border-radius: 5px;
    font-size: 20px;
    background-color: rgb(18, 22, 31);
    @media (max-width: 550px) {
      height: 30px;
      font-size: 14px;
    }

    &:nth-child(2) {
      margin: 0 8px;
    }
    .active {
      background-image: url(./img/nav-bg.png);
      background-size: 100% 100%;
      color: rgb(255, 198, 0);
      position: relative;
    }
    .active::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      background: rgb(255, 198, 0);
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      bottom: 0px;
      left: 0px;
      @media (max-width: 550px) {
        height: 2px;
      }
    }
  }
}
.navList2 {
  width: 100%;
  height: 50px;
  // background-color: red;
  margin-top: 10px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  color: white;
  display: flex;
  justify-content: center;
  @media (max-width: 550px) {
    height: 40px;
    font-size: 14px;
  }
  .item {
    position: relative;
    cursor: pointer;
    .active {
      color: rgb(255, 198, 0);
      font-weight: 700;
      &::after {
        display: block;
        content: "";
        position: absolute;
        width: 80%;
        height: 3px;
        background: rgb(255, 198, 0);
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 550px) {
          height: 2px;
        }
      }
    }
    &:nth-child(1) {
      margin-right: 25px;
    }
  }
}
.room {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 550px) {
    // margin-left: 2%;
    margin-top: 10px;
  }
  .item {
    // padding: 0 20px;
    padding: 0 1vw;
    margin-top: 17px;
    margin-right: 17px;
    box-sizing: border-box;
    width: 23.8%;
    height: 25vw;
    background: #292f47;
	position: relative;
    // background-color: #13161b;
    // background-image: url(./img/list-item-bg.png);
    // background-repeat: no-repeat;
    // background-size: 100%;
    @media (max-width: 550px) {
      width: 100%;
      height: 100vw;
      margin-right: 0;
    }
    .top {
      @media (max-width: 550px) {
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
      }
    }
    .avatar {
      width: 100%;
      height: auto;
      text-align: center;
      // margin-top: 20px;
      // margin-top: 1vw;
      margin-top: 4%;
      position: relative;
      @media (max-width: 550px) {
        // width: 30vw;
        width: auto;
        margin-right: 15px;
      }
      .img {
        width: 5vw;
        height: 5vw;
        // width: 90px;
        // height: 90px;
        border-radius: 50%;
        border: 3px solid #d8d8d8;
        @media (max-width: 550px) {
          width: 70px !important;
          height: 70px !important;
        }
      }
      .bo {
        position: absolute;
        bottom: 0px;
        left: 50%;
        width: 90px;
        height: auto;
        transform: translate(-50%, 0%);
        @media (max-width: 550px) {
          width: 60px;
        }
      }
    }
    .row1 {
      width: 100%;
      // margin-top: 15px;
      margin-top: 0.7vw;
      text-align: center;
      font-size: 14px;
      .time {
        color: #666d7e;
		margin: 12px 0;
      }
      .r1 {
        font-size: 22px;
        // font-weight: 700;
      }

      @media (max-width: 550px) {
		margin-top: 3vh;
        // text-align: start;
        // font-size: 13px;
        // .r1 {
        //   font-size: 18px;
        //   margin-bottom: 5px;
        // }
      }
    }
    .info {
      // margin-top: 20px;
      // margin-top: 1vw;
      margin-top: 4%;
      width: 100%;
      // height: ;
      display: flex;
      font-weight: 700;
      padding: 5px 0;
      .center {
        border-right: 1px solid rgb(32, 34, 41);
        border-left: 1px solid rgb(32, 34, 41);
      }
      div {
        flex: 1;
        // background-color: red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .s1 {
          @media (max-width: 550px) {
            font-size: 14px;
          }
        }
        .s2 {
          color: rgb(255, 198, 0);
          font-size: 22px;
          @media (max-width: 550px) {
            font-size: 18px;
          }
        }
      }
    }
    .wuqi {
      width: 100%;
      height: 11vw;
      // margin-top: 20px;
      // margin-top: 1vw;
      margin-top: 3%;
      // background-color: red;
      display: flex;
      justify-content: space-between;
	  @media (max-width: 550px) {
		width: 90%;
		margin: 0 auto;
		height: auto;
		margin-top: 20px;
	  }
      .wuqi-info {
        position: absolute;
        bottom: 0px;
        font-size: 12px;
        background: #131724;
        padding: 8px 0px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
      }
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .name,
      .money {
        width: 90%;
        margin: 0 auto;
      }
      .money {
        font-size: 12px;
        img {
          width: 16px;
          height: auto;
        }
      }
      .left {
        width: 48%;
        height: 8vw;
        background: url("../assets/img/openBox/item-bg.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // background-image: url(./img/wuqi-bg.png);
        // background-size: 100%;
        // border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
		
        .border {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -0.375rem;
          width: 0.375rem;
          z-index: 0;
          background-image: linear-gradient(
            to bottom,
            #ffae39 0%,
            #b46d06 100%
          );
        }
        @media (max-width: 550px) {
          height: 35vw;
		  width: 46%;
        }
        img {
          width: 80%;
          height: auto;
        }
      }
      .right {
        width: 48%;
        height: 8vw;
        display: flex;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media (max-width: 550px) {
          height: 53vw;
        }
        img {
          width: 80%;
          height: auto;
        }
        // .top,
        // .bottom {
        //   position: relative;
        // }
        // div {
        //   width: 100%;
        //   height: 5.1vw;
        //   background-image: url(./img/wuqi-bg.png);
        //   background-size: 100%;
        //   border-radius: 10px;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   @media (max-width: 550px) {
        //     height: 47%;
        //   }
        //   img {
        //     width: 70%;
        //     height: auto;
        //   }
        // }
      }
    }
    .bottoms {
      width: 70%;
    //   height: 3vw;
      // margin-top: 25px;
      // margin-top: 1.2vw;
      margin-top: 7%;
      background-image: linear-gradient(
        90deg,
        transparent,
        rgba(61, 90, 254, 0.25)
      );
      border: 2px solid #3d5afe;
      border-radius: 99vw;
    //   margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
	  margin: 0 auto;
	  padding: 0.625rem 3.5rem;
	  box-sizing: border-box;
      @media (max-width: 550px) {
        margin-top: 20px;
        height: 10vw;
        font-size: 15px;
        img {
          width: 22px !important;
        }
      }
      img {
        width: 30px;
        height: auto;
        margin-right: 5px;
      }
    }
  }
  .item-border{
	position: absolute;
	top: 0;
    left:0;
    width: 100%;
	height: 0.375rem;
    z-index: 0;
    background-image: linear-gradient(to bottom, #4b69ff 0, #2740bb 100%);
  }
}

//页数
.roll-page {
  margin: 10px 0 0 -10px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roll-page
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #e9b10e;
  color: #1a1c24;
}

.roll-page /deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #333542;
  color: #848492;
}
.main-cese-button {
  border: none;
}
.main-cese-button .item-box {
  margin: 12px 0px;
}
.main-cese-button .type {
  padding-bottom: 0px;
  background: transparent;
  padding: 0;
  overflow: visible;
}
.main-cese-button .type .item {
  color: #cedbea;
}
.main-cese-button .type .active {
  background: transparent !important;
  color: #fff !important;
  border-bottom: 2px solid #3d5afe;
  border-radius: 0px !important;
}
.breadcrumb {
  padding: 0px;
}
</style>