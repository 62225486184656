<template>
  <div class="container">
    <div class="vip-container">
      <!-- 面包屑导航 -->
      <div class="openbox-container_header breadcrumb">
        <div class="cat-breadcrumb" @click="$router.go(-1)">CSGOOP</div>
        <div class="ng-binding">
          <img src="../assets/img/chevron-down-1.svg" alt="" />
          VIP 
        </div>
      </div>
     <div class="noLogin" v-if="!$store.state.loginStates">
      {{ $t("登录成为会员，享受更多优惠") }}
     </div>
      <!-- vip等级 -->
      <div class="user-level" v-else>
        <div class="user-ref-left">
          <div
            class="shield ng-binding"
            :style="'--vipLevel: ' + `url('${getVipLevel(grade)}')`"
          >
            {{ grade }}
          </div>
          <div class="user-stats">
            <div class="level-label ng-binding">{{ $t("你的VIP等级") }}</div>
            <div class="user-level-name ng-binding">VIP {{ grade }}</div>
            <div class="next-level-label ng-binding">
              {{ $t("充值") }} {{ xu_recharge }} {{ $t("升级至下一级") }}
            </div>
            <div class="user-level-progress-bar">
              <div
                class="user-level-progress-content"
                :style="{ width: 100 - progress + '%' }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- vip列表 -->
    <div class="all-levels-container">
      <div class="table-header block-header ng-binding">ALL LEVELS</div>
      <table class="ref-levels">
        <tbody>
          <tr>
            <th colspan="2" class="ng-binding">{{ $t("等级") }}:</th>
            <th class="ng-binding">{{ $t("升级条件") }}:</th>
            <th class="ng-binding">{{ $t("充值加成") }}:</th>
            <th class="ng-binding">{{ $t("奖励金额") }}:</th>
          </tr>
          <tr v-for="(item, index) in tableData" :key="index" class="ng-scope">
            <td style="width: 70px">
              <div
                class="shield small-shield ng-binding"
                :style="'--vipLevel: ' + `url('${getVipLevel(item.grade)}')`"
              >
                {{ item.grade }}
              </div>
            </td>
            <td>
              <strong class="ng-binding">Level {{ item.grade }}</strong>
            </td>
            <td class="ng-binding">{{ item.upgrade }}</td>
            <td class="ng-binding">{{ item.rebate }}%</td>
            <td class="ng-binding">{{ item.reward }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { parse } from "qs";
import navheader from "@/components/navheader.vue";
export default {
  components: { navheader },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      grade: "",
      recharge: "",
      xu_recharge: "",
      totalSize: 0,
      page: 1,
      pageSize: 10,
      tableData: [],
      tableData1: [],
      totalSize: 0,
      pageSize: 1000,
      totalSize1: 0,
      pageSize1: 10,
      progress: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.getVipLevelData();
      this.getPlayerInfo();
    },
    getVipLevel(level) {
      try {
        return require(`../assets/img/shield_tier_${level}.svg`);
      } catch {
        return "";
      }
    },
    getVipLevelData() {
      this.$axios.post("/api/welfare/vipfuli", {}).then((res) => {
        var data = res.data;
        if (data.code == "1" && data.data != null) {
          this.tableData = data.data.list;
          this.grade = data.data.grade;
          this.recharge = data.data.recharge;
          this.xu_recharge = data.data.xu_recharge;
        }
      });
    },
    getPlayerInfo() {
      this.$axios.post("/api/player/playerdata", {}).then((res) => {
        if (res.data.code == 1) {
          this.progress = res.data.data.player.jindu;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.vip-container {
  padding: 40px 56px;
}
.breadcrumb {
  padding: 0px;
}
.container {
  background: url("../assets/img/wrapper-bg.jpg") no-repeat 50% -87px #1e2335;
}
.user-level {
  background: #282f46;
  color: #fff;
  padding: 40px 80px;
  font-weight: 700;
  margin-top: 30px;
}
.breadcrumbs-block {
  margin-top: 30px;
}
.user-ref-left {
  width: 100%;
  display: flex;
}
.user-ref-left .shield {
  box-sizing: border-box;
  background-size: contain;
  background-position-x: 8px;
  background-position-y: -6px;
}
.shield {
  width: 158px;
  height: 179px;
  background: var(--vipLevel) no-repeat;
  text-align: center;
  font-size: 70px;
  padding-top: 26px;
  margin-right: 60px;
}
.user-stats {
  min-width: 200px;
}
.level-label {
  text-transform: uppercase;
  font-size: 16px;
}
.user-level-name {
  font-size: 50px;
  padding: 30px 0 40px;
}
.next-level-label {
  color: #a5aabd;
  font-size: 12px;
  font-weight: 400;
}
.user-level-progress-bar {
  height: 11px;
  width: 100%;
  background-color: #1e2335;
  border-radius: 8px;
  margin-top: 15px;
}
.user-level-progress-content {
  border-radius: 8px;
  height: 100%;
  background: linear-gradient(to right, #0a9b47 0%, #2ecd71 100%);
}
.all-levels-container {
  background: #1e2335;
  color: #fff;
  font-weight: 400;
  padding: 40px 56px;
}
.block-header {
  text-transform: uppercase;
  height: 30px;
}
.ref-levels {
  width: 100%;
  margin-top: 20px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ref-levels th {
  color: #a5aabd;
  font-size: 12px;
  text-align: left;
  padding-bottom: 15px;
}
.small-shield {
  width: 50px;
  height: 58px;
  font-size: 22px;
  padding-top: 17px;
  box-sizing: content-box;
  margin-right: 0;
  position: relative;
  top: 9px;
  background-position-x: 1px;
}
.ref-levels td {
  border-bottom: 1px solid #3d4451;
  padding: 5px;
  vertical-align: middle;
  height: 70px;
}
@media screen and (max-width: 764px) {
  .vip-container {
    padding: 40px 15px;
  }

  .user-ref-left {
    flex-direction: column;
    text-align: center;
  }
  .user-ref-left .shield {
        margin-left: auto;
        margin-right: auto;
        clear: both;
        float: none;
    }
    .all-levels-container{
      padding: 40px 15px;
    }
}
.noLogin{
  text-align: center;
  color: #fff;
}
</style>
