<template>
  <div class="container">
    <div class="vip-container">
      <!-- 面包屑导航 -->
      <div class="openbox-container_header breadcrumb">
        <div class="cat-breadcrumb" @click="$router.go(-1)">CSGOOP</div>
        <div class="ng-binding">
          <img src="../assets/img/chevron-down-1.svg" alt="" />
          {{ $t("邀请") }}
        </div>
      </div>
      <div class="noLogin" v-if="!$store.state.loginStates">
        {{ $t("登录成为会员，享受更多优惠") }}
      </div>
      <!-- vip等级 -->
      <div class="user-level" v-else>
        <div class="user-ref-right">
          <label class="ng-binding">{{ $t("推广链接") }}</label>
          <div class="button-input button-input-ref">
            <a class="tippy" @click="copyText(valueUrl)"></a>
            <input
              class="round-input input-reflink ng-pristine ng-untouched ng-valid ng-not-empty"
              type="text"
              disabled
              :value="valueUrl"
            />
            <el-tooltip
              class="tippy tippy-round"
              effect="dark"
              :content="$t('发送你的链接给你的朋友和赚取免费美元')"
              placement="top"
            >
              <div>?</div>
            </el-tooltip>
            <!-- <span id="ref_link" style="display: none" class="ng-binding"
              >https://csgocases.com/r/g63638hxf</span
            > -->
          </div>

          <label class="ng-binding">{{ $t("邀请码") }}</label>
          <div class="button-input button-input-short">
            <a class="tippy" @click="copyText(valueCode)"></a>
            <input
              type="text"
              class="round-input ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-pattern"
              :value="valueCode"
            />
            <!-- <input
              pattern="[a-zA-Z0-9]+"
              ng-readonly="vm.auth.user.ref_changed"
              id="ref_val"
             
              type="text"
              data-ng-model="vm.ref"
              field_signature="991952915"
              form_signature="17451075564250106388"
              alternative_form_signature="1779565731224483454"
              visibility_annotation="false"
            /> -->
            <!-- <span id="ref_val" style="display: none" class="ng-binding"></span> -->
          </div>

          <!-- <a
            data-ng-if="!vm.auth.user.ref_changed"
            data-ng-click="vm.changeRef()"
            class="button button-green button-change-ref ng-binding ng-scope"
            style="line-height: 40px !important"
          >
            Change
          </a> -->
          <div class="clear"></div>
        </div>
      </div>
      <!-- 邀请人数 -->
      <div class="user-blocks ng-scope">
        <div class="block block-1">
          <div class="block-header ng-binding">
            {{ $t("邀请人数") }}
            <el-tooltip
              class="tippy user-blocks-tooltip"
              effect="dark"
              :content="$t('你的邀请人数')"
              placement="top"
            >
              <div>?</div>
            </el-tooltip>
          </div>
          <div class="big-number blue-number ng-binding">{{ people_num }}</div>
        </div>
        <div class="block block-2">
          <div class="block-header ng-binding">
            {{ $t("累计充值") }}
            <el-tooltip
              class="tippy user-blocks-tooltip"
              effect="dark"
              :content="$t('你的累计充值')"
              placement="top"
            >
              <div>?</div>
            </el-tooltip>
          </div>
          <div class="big-number green-number ng-binding">
            {{ invite_commission }}
          </div>
        </div>
        <div class="block block-3">
          <div class="block-header ng-binding">
            {{ $t("充值收益") }}
            <el-tooltip
              class="tippy user-blocks-tooltip"
              effect="dark"
              :content="$t('你的充值收益')"
              placement="top"
            >
              <div>?</div>
            </el-tooltip>
          </div>
          <div class="big-number ng-binding">{{ ratio }}%</div>
        </div>
        <div class="block block-4">
          <div class="block-header ng-binding">
            {{ $t("消耗收益") }}
            <el-tooltip
              class="tippy user-blocks-tooltip"
              effect="dark"
              :content="$t('你的消耗收益')"
              placement="top"
            >
              <div>?</div>
            </el-tooltip>
          </div>
          <div class="big-number ng-binding">{{ water_ratio }}%</div>
        </div>
        <div class="block block-5">
          <div class="block-header ng-binding">
            {{ $t("我的邀请人") }}
            <el-tooltip
              class="tippy user-blocks-tooltip"
              effect="dark"
              :content="$t('我的邀请人')"
              placement="top"
            >
              <div>?</div>
            </el-tooltip>
          </div>
          <div class="big-number ng-binding">{{ tuiguangren }}</div>
        </div>
        <div class="clear"></div>
      </div>
    </div>

    <!-- vip列表 -->
    <div class="all-levels-container">
      <div class="main-cese-button">
        <div class="item-box">
          <div
            class="main-cese-item"
            v-for="(item, index) in typeList"
            :key="index"
            :class="{ 'case-active': type == item.value }"
            @click="changeType(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <table class="ref-levels">
        <tbody>
          <tr v-if="type == 1">
            <th class="ng-binding">{{ $t("用户名") }}:</th>
            <th class="ng-binding">{{ $t("累计充值") }}:</th>
            <th class="ng-binding">{{ $t("累计流水") }}:</th>
            <th class="ng-binding">{{ $t("绑定方式") }}:</th>
            <th class="ng-binding">{{ $t("绑定时间") }}:</th>
          </tr>
          <tr v-if="type == 2">
            <th class="ng-binding">{{ $t("用户名") }}:</th>
            <th class="ng-binding">{{ $t("充值金额") }}:</th>
            <th class="ng-binding">{{ $t("比例") }}:</th>
            <th class="ng-binding">{{ $t("收益") }}:</th>
            <th class="ng-binding">{{ $t("充值时间") }}:</th>
          </tr>
          <tr v-if="type == 3">
            <th class="ng-binding">{{ $t("用户名") }}:</th>
            <th class="ng-binding">{{ $t("消耗金额") }}:</th>
            <th class="ng-binding">{{ $t("比例") }}:</th>
            <th class="ng-binding">{{ $t("收益") }}:</th>
            <th class="ng-binding">{{ $t("消耗时间") }}:</th>
          </tr>

          <template v-if="type == 1 && listData.length > 0">
            <tr v-for="(item, index) in listData" :key="index" class="ng-scope">
              <td class="ng-binding">{{ item.name }}</td>
              <td class="ng-binding">{{ item.recharge }}</td>
              <td class="ng-binding">{{ item.upgrade }}</td>
              <td class="ng-binding">{{ item.total_amount }}</td>
              <td class="ng-binding">{{ item.source }}</td>
              <td class="ng-binding">{{ item.createtime }}</td>
            </tr>
          </template>
          <template v-else-if="type == 2 && listData.length > 0">
            <tr v-for="(item, index) in listData" :key="index" class="ng-scope">
              <td class="ng-binding">{{ item.name }}</td>
              <td class="ng-binding">{{ item.recharge }}</td>
              <td class="ng-binding">{{ item.ratio }}</td>
              <td class="ng-binding">{{ item.money }}</td>
              <td class="ng-binding">{{ item.createtime }}</td>
            </tr>
          </template>
          <template v-else-if="type == 3 && listData.length > 0">
            <tr v-for="(item, index) in listData" :key="index" class="ng-scope">
              <td class="ng-binding">{{ item.name }}</td>
              <td class="ng-binding">{{ item.recharge }}</td>
              <td class="ng-binding">{{ item.ratio }}</td>
              <td class="ng-binding">{{ item.money }}</td>
              <td class="ng-binding">{{ item.createtime }}</td>
            </tr>
          </template>
          <tr colspan="" v-else class="empty">
            <td colspan="10">{{ $t("暂无数据") }}</td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="total > 0"
        :total="total"
        :currentPage="page"
        :perPage="pageSize"
        :showNav="showNav"
        @current-change="currentChange"
      ></pagination>
    </div>
  </div>
</template>

<script>
import { parse } from "qs";
import navheader from "@/components/navheader.vue";
import pagination from "@/components/pagination.vue";
export default {
  components: { navheader, pagination },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      valueUrl: "",
      valueCode: "",
      tuiguangren: "",
      people_num: "",
      ratio: "",
      water_ratio: "",
      invite_commission: "",
      invite_earning: "",
      language: localStorage.getItem("lang") || "zh",
      type: 1,
      typeList: [
        {
          name: this.$t("邀请记录"),
          value: 1,
          url: "/api/record/offlinelist",
        },
        {
          name: this.$t("充值奖励"),
          value: 2,
          url: "/api/record/offline",
        },
        {
          name: this.$t("消耗奖励"),
          value: 3,
          url: "/api/record/waterlist",
        },
      ],
      queryUrl: "/api/record/offlinelist",
      page: 1,
      pagesize: 20,
      total: 0,
      listData: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.getList();
      this.getRewardList();
      this.getVipLevelData();
      this.getPlayerInfo();
    },
    getVipLevel(level) {
      try {
        return require(`../assets/img/shield_tier_${level}.svg`);
      } catch {
        return "";
      }
    },
    getVipLevelData() {
      this.$axios.post("/api/welfare/vipfuli", {}).then((res) => {
        var data = res.data;
        if (data.code == "1" && data.data != null) {
          this.tableData = data.data.list;
          this.grade = data.data.grade;
          this.recharge = data.data.recharge;
          this.xu_recharge = data.data.xu_recharge;
        }
      });
    },
    getPlayerInfo() {
      this.$axios.post("/api/player/playerdata", {}).then((res) => {
        if (res.data.code == 1) {
          this.progress = res.data.data.player.jindu;
        }
      });
    },
    //复制
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: this.$t("profileMessage.copySuccess"),
        type: "success",
        customClass: "message-styles",
      });
    },
    //我的推广链接和邀请码
    getList() {
      let param = {};
      this.$axios
        .post("/api/player/inviteinfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.code == 1) {
            this.valueUrl = data.data.info.invite_url;
            this.valueCode = data.data.info.invite_code;
            this.people_num = data.data.people_num;
            this.ratio = data.data.ratio;
            this.water_ratio = data.data.water_ratio;
            this.invite_commission = data.data.invite_commission;
            this.invite_earning = data.data.invite_earning;
          }
        });
      this.$axios
        .post("/api/player/playerdata", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          console.log(data, "测试数据");
          if (data.code == 1) {
            let myinviter = data.data.myinviter;
            if (myinviter) {
              this.tuiguangren = myinviter.name;
            } else {
              this.language == "zh"
                ? (this.tuiguangren = "暂无")
                : (this.tuiguangren = "No");
            }
          }
        });
    },
    changeType(item) {
      this.type = item.value;
      this.queryUrl = item.url;
      this.getRewardList();
    },
    // 获取奖励列表
    getRewardList() {
      let param = {
        page: this.page,
        pagesize: this.pagesize,
      };
      this.$axios.post(this.queryUrl, this.$qs.stringify(param)).then((res) => {
        let data = res.data;
        if (data.code == 1) {
          this.listData = data.data.list.data;
          this.total = data.data.list.total;
        }
      });
    },
    // 页码切换
    currentChange(index) {
      this.page = index;
      this.getRewardList();
    },
  },
};
</script>

<style lang="less" scoped>
.vip-container {
  padding: 40px 56px;
}
.breadcrumb {
  padding: 0px;
}
.container {
  background: url("../assets/img/wrapper-bg.jpg") no-repeat 50% -87px #1e2335;
}
.user-level {
  background: #282f46;
  color: #fff;
  padding: 40px 80px;
  font-weight: 700;
  margin-top: 30px;
}
.breadcrumbs-block {
  margin-top: 30px;
}

.shield {
  width: 158px;
  height: 179px;
  background: var(--vipLevel) no-repeat;
  text-align: center;
  font-size: 70px;
  padding-top: 26px;
  margin-right: 60px;
}
.user-stats {
  min-width: 200px;
}
.level-label {
  text-transform: uppercase;
  font-size: 16px;
}
.user-level-name {
  font-size: 50px;
  padding: 30px 0 40px;
}
.next-level-label {
  color: #a5aabd;
  font-size: 12px;
  font-weight: 400;
}
.user-level-progress-bar {
  height: 11px;
  width: 100%;
  background-color: #1e2335;
  border-radius: 8px;
  margin-top: 15px;
}
.user-level-progress-content {
  border-radius: 8px;
  height: 100%;
  background: linear-gradient(to right, #0a9b47 0%, #2ecd71 100%);
}
.all-levels-container {
  background: #1e2335;
  color: #fff;
  font-weight: 400;
  padding: 40px 56px;
}
.block-header {
  text-transform: uppercase;
  height: 30px;
}
.ref-levels {
  width: 100%;
  margin-top: 20px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ref-levels th {
  color: #a5aabd;
  font-size: 12px;
  text-align: left;
  padding-bottom: 15px;
}
.small-shield {
  width: 50px;
  height: 58px;
  font-size: 22px;
  padding-top: 17px;
  box-sizing: content-box;
  margin-right: 0;
  position: relative;
  top: 9px;
  background-position-x: 1px;
}
.ref-levels td {
  border-bottom: 1px solid #3d4451;
  padding: 5px;
  vertical-align: middle;
  height: 70px;
}
.user-ref-right {
  width: 100%;
  font-weight: 400;
}
.user-ref-right label {
  display: block;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 0 10px;
}
.button-input {
  position: relative;
  display: flex;
  align-items: center;
}
.button-input-ref a {
  left: 304px;
}
.button-input a {
  position: absolute;
  background: url("../assets/img/copy.svg") no-repeat 50% 50%;
  width: 40px;
  height: 40px;
  top: 2px;
}
.input-reflink {
  width: 310px;
}
.round-input {
  border: 2px solid #4d515f;
  background: 0 0;
  padding: 13px 18px;
  color: #fff;
  font-size: 12px;
  border-radius: 50px;
}
.section-swap .tippy,
.block .tippy,
.free-usd-left-block .tippy,
.user-panel-buttons .tippy,
.item-top .tippy,
.tippy-round {
  background: #484e62;
  // padding: 4px 9px;
  position: relative;
  left: 5px;
  border-radius: 50px;
  height: 1.5rem;
  width: 1.5rem;
  display: grid;
  place-items: center;
}
.button-input-short {
  float: left;
}
.button-change-ref {
  margin-left: 14px;
  border-color: #2fcf72;
  background: rgba(47, 204, 114, 0.2);
  font-weight: 700;
  color: #fff;
  float: left;
  width: 140px;
  font-size: 14px;
  text-align: center;
  font-family: inherit;
  border-radius: 99px;
  border: 2px solid #3d5afe;
}
.button-green {
  border-color: #27ae60;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(39, 174, 96, 0) 0%,
    rgba(39, 174, 96, 0.3) 100%
  );
}
.button-input-short.button-input a {
  right: 5px;
  left: unset;
}
.user-blocks {
  padding: 20px 0;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}
.user-blocks .block {
  overflow: hidden;
}
.block:first-child {
  margin-left: 0;
}
.block {
  text-align: center;
  background: #282f46;
  float: left;
  box-sizing: border-box;
  padding: 30px 10px;
  width: 17.8%;
  height: 190px;
  margin-left: 1%;
}
.block-header {
  text-transform: uppercase;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-blocks .big-number {
  line-height: 46px;
  padding-top: 12px;
}
.blue-number {
  color: #3d5afe;
  text-shadow: 0 0 45px #3d5afe;
}

.big-number {
  font-size: 40px;
  padding: 25px 0 30px;
  color: #be4449;
  text-shadow: 0 0 45px #be4449;
}
@media screen and (max-width: 764px) {
  .vip-container {
    padding: 40px 15px;
  }
  .user-level {
    padding: 30px 10px;
  }
  .all-levels-container {
    padding: 40px 15px;
  }
  .block {
    width: 49%;
    margin-bottom: 10px;
    font-size: 12px;
    margin-left: 0;
    margin-right: 1% !important;
  }
}
.noLogin {
  text-align: center;
  color: #fff;
}
.item-box{
  background: transparent !important;
  display: flex;
  .main-cese-item{
    color: #cedbea ;
    padding: 0 20px;
  }
  
}
.case-active {
  background: transparent  !important;
  color: #fff !important;
  border-bottom: 2px solid #3d5afe;
  // background: #3d5afe;
  border-radius: 0px !important;
}
.empty {
  padding: 40px;
  text-align: center;
  color: #fff;
}
</style>
