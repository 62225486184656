import { render, staticRenderFns } from "./Invite.vue?vue&type=template&id=245d2e11&scoped=true&"
import script from "./Invite.vue?vue&type=script&lang=js&"
export * from "./Invite.vue?vue&type=script&lang=js&"
import style0 from "./Invite.vue?vue&type=style&index=0&id=245d2e11&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245d2e11",
  null
  
)

export default component.exports