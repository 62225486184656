<template>
  <div class="main">
    <div class="lottery" v-if="boxInfo">
      <div class="zhe" v-if="close"></div>
      <div class="ruleBox" v-if="close">
        <div class="cancel" @click="close = false">
          <img src="./img/close.png" alt="" />
        </div>
        <div class="title">
          <div>玩法规则</div>
        </div>
        <div class="con">
          <div v-html="content" style="margin-top: 20px"></div>
        </div>
      </div>
      <div class="back">
        <div class="left" @click="$router.push('/Index')">CSGOOP</div>
        <div class="ng-binding">
          <img src="../assets/img/chevron-down-1.svg" alt="" />
          {{ boxInfo.title }}
        </div>
      </div>
      <!-- 时间盲盒 -->
      <div class="timeBox">
        <div class="left">
          <!-- 标题 -->
          <div class="lottery-header">{{ boxInfo.title }}</div>
          <!-- 中间盒子 -->
          <div class="lottery-skins-img">
            <img :src="boxInfo.imageUrl" alt="" />
          </div>
          <!-- 饰品名称 -->
          <div class="lottery-item-name">
            <div class="name">{{ getShortName(boxInfo) }}</div>
            <div class="short-name">({{ getExteriorName(boxInfo) }})</div>
          </div>
          <!-- 倒计时 -->
          <div class="free-usd-bottom">
            <div class="counting-clock-header">
              <span class="free-usd-bottom-label">{{ $t("倒计时") }}</span>
            </div>
            <table class="clock">
              <tbody>
                <tr class="digital-clock">
                  <td class="" v-if="timeInfo.days > 0">{{ timeInfo.days }}</td>
                  <td class="grey small-col" v-if="timeInfo.days > 0">:</td>
                  <td class="">{{ timeInfo.hours }}</td>
                  <td class="grey small-col">:</td>
                  <td class="">{{ timeInfo.minutes }}</td>
                  <td class="grey small-col">:</td>
                  <td class="">{{ timeInfo.seconds }}</td>
                </tr>
                <tr class="digital-clock-label">
                  <td class="" v-if="timeInfo.days > 0">{{ $t("天") }}</td>
                  <td class="small-col" v-if="timeInfo.days > 0"></td>
                  <td class="">{{ $t("时") }}</td>
                  <td class="small-col"></td>
                  <td class="">{{ $t("分") }}</td>
                  <td class="small-col"></td>
                  <td class="">{{ $t("秒") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="right">
          <!-- tab栏切换 -->
          <!-- <div class="lottery-block-header lottery-block-header-first">
          <strong>{{ $t("用户") }}</strong>
          <span> {{ $t("参与人数") }}: {{  }} </span>
        </div> -->
          <div class="lottery-block-header lottery-block-header-sub">
            <strong>{{ $t("历史掉落") }}</strong>
            <span v-if="List.length > 0">
              {{ $t("最后的赢家") }}：{{
                timeAgo(List[List.length - 1].createtime)
              }}</span
            >
          </div>
          <!-- 内容 -->
          <div class="free-usd-winners">
            <!-- 加入按钮 -->
            <div
              class="button-join-giveaway"
              @click="join(boxInfo.id)"
              v-if="boxInfo.is_active == 0"
            >
              {{ $t("立即参与") }}
            </div>
            <div class="clear"></div>
            <div class="lottery-user" v-for="item in List" :key="item.id">
              <!-- 用户数据 -->
              <div class="lottery-user-data">
                <div class="lottery-user-avatar">
                  <img :src="item.image" alt="" />
                </div>
                <div>
                  <div class="lottery-user-name">{{ item.name }}</div>
                  <div class="weapon-name">{{ getShortName(item) }}</div>
                </div>
              </div>
              <!-- 掉落时间 -->
              <div class="user-right">
                <div class="round-span">{{ timeAgo(item.createtime) }}</div>
                <img :src="item.imageUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyHomebot></MyHomebot>
  </div>
</template>

<script>
import MyHomebot from "./my_homebot.vue";
import moment from "moment";
export default {
  components: {
    MyHomebot,
  },
  data() {
    return {
      timeBoxId: this.$route.query.box_id,
      close: false,
      hTime: 0,
      mTime: 0,
      sTime: 0,
      time: "",
      timeInfo: {},
      // time2: "",
      // timeInfo2: {},
      // time3: "",
      // timeInfo3: {},
      boxInfo: [],
      timeInfo: [],
      timer: null,
      oneId: "",
      twoId: "",
      threeId: "",
      List: [],
      twoList: [],
      threeList: [],
      total: "",
      total2: "",
      total3: "",
      showTop: 0,
      showList: 0,
      showAll: true,
      content: "",
      language:
        localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "zh",
    };
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  created() {},
  async mounted() {
    if (document.body.clientWidth > 600) {
      this.showAll = true;
    } else {
      this.showAll = false;
    }
    await this.getInfo();
    // this.timeInfo = [
    //   this.getTime(this.time1),
    //   this.getTime(this.time2),
    //   this.getTime(this.time3),
    // ];
    this.timer = setInterval(() => {
      (this.timeInfo = this.getTime(this.time)), this.time--;
    }, 1000);
    // this.getRule();
  },
  methods: {
    getRule() {
      this.$axios({
        url: "/api/index/ruletextlist",
        method: "post",
      }).then((res) => {
        if (res.data.code == 1) {
          let data = res.data.data;
          for (let i = 0; i < data.length; i++) {
            if ("时间盲盒".includes(data[i].name)) {
              this.content = data[i].center;
              return;
            }
          }
        }
      });
    },
    goRule() {
      if (this.showAll) {
        this.close = true;
      } else {
        this.$router.push({ name: "Rule", params: { title: "时间盲盒" } });
      }
    },
    getInfo() {
      console.log("请求数据");
      this.$axios({
        url: "/api/rechargetime/rechargetimelist",
        method: "post",
      }).then((res) => {
        if (res.data.code == 1) {
          const data = res.data.data;
          // 过滤
          const filterData = data.filter((item) => {
            return item.id == this.timeBoxId;
          });
          this.boxInfo = filterData[0];
          this.time = this.boxInfo.down_time;
          this.getList();
        }
      });
    },
    getList(page = 1) {
      this.$axios({
        url: "/api/rechargetime/recordlist",
        method: "post",
        data: {
          id: this.timeBoxId,
          page: page,
          pagesize: 999,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.List = res.data.data.data;
          this.total = res.data.data.total;
        }
      });
    },
    timeAgo(item) {
      const now = moment(); // 获取当前时间
      const time = moment.unix(item); // 解析时间字符串，指定格式
      const diffInMinutes = now.diff(time, "minutes"); // 计算分钟差
      if (diffInMinutes < 60) {
        return `${diffInMinutes}min ago`;
      }

      const diffInHours = now.diff(time, "hours"); // 计算小时差
      if (diffInHours < 24) {
        return `${diffInHours}h ago`;
      }

      const diffInDays = now.diff(time, "days"); // 计算天数差
      return `${diffInDays}d ago`;
    },
    // currentChange1(index) {
    //   this.getList1(index);
    // },
    // currentChange2(index) {
    //   this.getList2(index);
    // },
    // currentChange3(index) {
    //   this.getList3(index);
    // },
    // getList2(page = 1) {
    //   this.$axios({
    //     url: "/api/rechargetime/recordlist",
    //     method: "post",
    //     data: {
    //       id: this.twoId,
    //       page: page,
    //       pagesize: 9,
    //     },
    //   }).then((res) => {
    //     if (res.data.code == 1) {
    //       this.twoList = res.data.data.data;
    //       this.total2 = res.data.data.total;
    //     }
    //   });
    // },
    // getList3(page = 1) {
    //   this.$axios({
    //     url: "/api/rechargetime/recordlist",
    //     method: "post",
    //     data: {
    //       id: this.threeId,
    //       page: page,
    //       pagesize: 9,
    //     },
    //   }).then((res) => {
    //     if (res.data.code == 1) {
    //       this.threeList = res.data.data.data;
    //       this.total3 = res.data.data.total;
    //     }
    //   });
    // },
    join(id) {
      if (!this.$store.state.loginStates) {
        this.$message({
          message: this.$t('public.noLogin'),
          type: "error",
          customClass: "message-styles",
        });
        return;
      }
      this.$axios({
        url: "/api/rechargetime/joinrechargetime",
        method: "post",
        data: { id },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message:
              this.language == "zh" ? res.data.msg : res.data.english_msg,
            customClass: "message-styles",
            type: "success",
          });
          this.getInfo();
        } else {
          this.$message({
            message:
              this.language == "zh" ? res.data.msg : res.data.english_msg,
            customClass: "message-styles",
            type: "error",
          });
        }
      });
    },
    getTime(time) {
      var data = time * 1000;
      var days = Math.floor(time / (3600 * 24));
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((data % (1000 * 60)) / 1000);

      // 格式化处理，确保不足两位数时前面补零
      days = padZero(days);
      hours = padZero(hours);
      minutes = padZero(minutes);
      seconds = padZero(seconds);

      function padZero(num) {
        return num < 10 ? "0" + num : num.toString();
      }

      return { days, hours, minutes, seconds };
    },
    getShortName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.shortName;
        case "en":
          return item.english_shortName;
        default:
          return item.shortName;
      }
    },
    getExteriorName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.exteriorName;
        case "en":
          return item.english_exteriorName;
        default:
          return item.exteriorName;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.lottery {
  width: calc(100% -12rem);
  padding: 40px 56px;
}
.main {
  width: 100%;
  height: 100%;
  background: url("../assets/img/wrapper-bg.jpg") no-repeat 50% -87px #1e2335;
  .zhe {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
  }
  .ruleBox {
    width: 530px;
    height: 480px;
    background-image: url(./img/box-bg.jpg);
    background-size: 100% 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 10px;
    z-index: 99999;
    padding-bottom: 30px;
    @media (max-width: 550px) {
      width: 90vw;
    }
    .cancel {
      position: fixed;
      top: 10px;
      right: 13px;
      transition: 0.4s;
      cursor: pointer;
      &:hover {
        transform: rotate(90deg);
      }
      img {
        width: 16px;
        height: auto;
      }
    }
    .title {
      font-size: 22px;
      margin-top: 40px;
    }
    .con {
      overflow-y: scroll;
      padding: 0 100px;
      @media (max-width: 550px) {
        padding: 0 50px;
      }
      p {
        margin-top: 20px;
      }
    }
  }
  .back {
    width: 100%;
    border-radius: 3px;
    height: 60px;
    line-height: 60px;
    background-color: transparent;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
    color: #a9abbc;
    font-size: 14px;
    display: flex;
    @media (max-width: 550px) {
      //   margin-top: 15px;
      height: 40px;
      line-height: 40px;
    }
    .left {
      cursor: pointer;
      &:hover {
        color: white;
        .l {
          border: 1.5px solid white !important;
        }
      }
    }
    .l {
      padding: 5px;
      border: 1.5px solid #383f4f;
      border-radius: 6px;
      margin-right: 6px;
    }
    .right {
      //   position: absolute;
      //   right: 30px;
      //   top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
      //   font-size: 16px;
      &:hover {
        color: white;
      }
      img {
        width: 20px;
        height: auto;
        margin-right: 6px;
      }
    }
  }
  .nav {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    color: white;
    .items {
      width: 33%;
      height: 35px;
      border-radius: 5px;
      margin: 0 2px;
      background-color: #14161a;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .active {
      background-color: #51420a !important;
      color: #ffc600 !important;
    }
  }
  .top {
    width: 100%;
    height: 440px;
    padding: 0 10%;
    box-sizing: border-box;
    display: flex;
    @media (max-width: 550px) {
      width: 100vw;
      margin-top: 10px;
      .box {
        width: 100% !important;
      }
    }
    .box {
      width: 33.3%;
      height: 100%;
      // background-color: red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .wuqi {
        width: 192px;
        height: 178px;
        background-image: url(./img/top-item-bg.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        img {
          width: auto;
          height: 55%;
        }
      }
      .title {
        color: white;
        margin-top: 10px;
      }
      .row1 {
        color: rgb(255, 162, 42);
      }
      .row2 {
        display: flex;
        justify-content: center;
        color: #f9c545;
        margin: 5px 0;
        img {
          width: 20px;
          height: auto;
        }
      }
      .row3 {
        color: white;
      }
      .row4 {
        margin-top: 10px;
        height: 50px;
        // background-color: red;
        display: flex;
        align-items: center;
        @media (max-width: 550px) {
          height: 14vw;
        }
        .left {
          text-align: center;
          height: 100%;
          font-size: 14px;
          margin-right: 20px;
          padding-top: 10px;
          @media (max-width: 550px) {
            padding-top: 5px;
            font-size: 13px;
            img {
              width: 26px;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          height: 100%;
          .s {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 36px;
            border-radius: 5px;
            background-color: #23262e;
            padding: 4px 0;
            box-sizing: border-box;
            @media (max-width: 550px) {
              width: 45px;
            }
            span {
              font-size: 12px;
              color: white;
              @media (max-width: 550px) {
                font-size: 14px;
              }
              &:nth-child(1) {
                color: #f9c545;
                font-weight: 700;
                font-size: 12px;
                @media (max-width: 550px) {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      .button {
        background: #ffc600;
        padding: 2px 30px;
        border-radius: 15px;
        color: #4f3d2e;
        margin-top: 15px;
        cursor: pointer;
      }
      .buttons {
        background: #585858;
        padding: 2px 30px;
        border-radius: 15px;
        color: white;
        margin-top: 15px;
        cursor: pointer;
      }
    }
  }
  .content {
    width: 100%;
    padding: 0 10%;
    box-sizing: border-box;
    display: flex;
    margin-top: 15px;
    @media (max-width: 550px) {
      padding: 0;
    }
    .item {
      width: 33.3%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 550px) {
        // width: 100%;
        width: 100vw;
      }
      .title {
        width: 90%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background-color: #12161f;
        border-radius: 10px;
        color: white;
        font-size: 24px;
        font-weight: 700;
      }
      .list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .box {
          width: 31.3%;
          height: 130px;
          margin: 0 1%;
          margin-top: 20px;
          overflow: hidden;
          color: white;
          &:hover {
            .all {
              transform: translate(0, -130px);
              transition: 0.5s;
            }
          }
          .all {
            transition: 0.5s;
          }
          .shang2 {
            background-image: url(./img/list-item-bg2.png) !important;
          }
          .shang3 {
            background-image: url(./img/list-item-bg3.png) !important;
          }
          .shang {
            width: 100%;
            height: 130px;
            background-image: url(./img/list-item-bg1.png);
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
              width: auto;
              height: 50%;
              margin-bottom: 20px;
            }
            span {
              display: block;
              width: 95%;
              margin-top: -15px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              @media (max-width: 550px) {
                font-size: 12px;
              }
            }
          }
          .xia {
            width: 100%;
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #f9c545;
            font-size: 14px;
            .img {
              width: 73px;
              height: 73px;
              background-image: url(./img/list-item-avatar-bg.png);
              background-size: 100% 100%;
              background-position: center center;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 10px;
            }
            img {
              width: 57px;
              height: auto;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .pagination {
      width: 100%;
      margin-top: 20px;
    }
  }
}

//页数
.roll-page {
  margin: 10px 0 0 -10px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roll-page
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #e9b10e;
  color: #1a1c24;
}

.roll-page /deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #333542;
  color: #848492;
}
.ng-binding {
  display: flex;
  align-items: center;
  color: #727989;
  img {
    width: 20px;
  }
}
.timeBox {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.timeBox .left {
  width: 38%;
  background: url("../assets/img/lottery-bg.webp") no-repeat 50% top;
  text-align: center;
  margin-bottom: 40px;
  box-shadow: 0 0 73px 5px #0000008a;
  max-width: 588px;
  height: 44rem;
}
.timeBox .left .lottery-header {
  font-size: 30px;
  font-weight: 700;
  line-height: 84px;
  padding-top: 10px;
  color: #3d5afe;
  text-shadow: 0 0 45px #3d5afe;
}
.timeBox .left .lottery-skins-img {
  position: relative;
  width: 100%;
  margin-top: 50px;
  img {
    width: 340px;
    padding: 20px;
    filter: brightness(1.1) saturate(1.15);
  }
}
.timeBox .left .lottery-item-name {
  color: #a5aabd;
  padding-bottom: 35px;
  font-weight: 400;
}
.timeBox .left .lottery-item-name .name {
  padding: 3px 10px 5px;
  color: #fff;
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
  display: block;
}
.timeBox .left .lottery-item-name .short-name {
  font-size: 16px;
}
.free-usd-bottom {
  background: #10121c;
  text-align: center;
  padding-bottom: 20px;
}
.counting-clock-header {
  color: #a5aabd;
  padding: 1.25rem 0 1rem;
  text-transform: uppercase;
}
.free-usd-bottom-label {
  width: 100%;
  background: url("../assets/img/clock.svg") no-repeat left 50%;
  padding: 3px 0 3px 30px;
  font-weight: 700;
  text-align: center;
}
table.clock {
  width: 100%;
  color: #eb4b4b;
}

table.clock .grey {
  color: #495260;
}

table.clock td {
  text-align: center;
}

table.clock td.small-col {
  width: 9.3%;
}

table.clock td {
  width: 24%;
}
.digital-clock {
  font-family: digital-7regular;
  font-size: 90px;
  height: 80px;
  color: #eb4b4b;
}

.digital-clock td {
  vertical-align: middle;
}

.digital-clock-label td {
  color: #a5aabd;
  font-family: Libre Franklin, sans-serif;
  font-size: 11px;
  padding-top: 5px;
  font-weight: 700;
}
.timeBox .right {
  width: 58%;
  // float: right;
  margin-bottom: 20px;
}
.lottery-block-header {
  box-sizing: border-box;
  color: #fff;
  font-weight: 700;
  padding: 20px 0 40px 72px;
  text-align: left;
  width: 49%;
  text-transform: uppercase;
  font-size: 20px;
  transition: all 0.2s linear;
  background: url("../assets/img/podium.svg") no-repeat left 40%;
  display: inline-block;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}
.lottery-block-header-first {
  background: url("../assets/img/trophy.svg") no-repeat left 40%;
}
.lottery-block-header strong {
  display: block;
  padding-top: 4px;
}
.lottery-block-header span {
  font-size: 14px;
  text-transform: none;
  padding-top: 12px;
  display: block;
  font-weight: 400;
}
.button-join-giveaway {
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  cursor: pointer;
  outline: none;
  border-color: #3d5afe;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(61, 90, 254, 0) 0%,
    rgba(61, 90, 254, 0.3) 100%
  );
  text-decoration: none;
  display: block;
  border: 2px solid #3d5afe;
  border-radius: 99px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  font-family: inherit;
  &:hover {
    background: #3d5afe;
  }
}
.lottery-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 2px solid #10121c;
  margin-top: 15px;
  padding: 22px;
  // background: linear-gradient(
  //   to right,
  //   rgba(16, 18, 28, 1) 0%,
  //   rgba(16, 18, 28, 0.1) 100%
  // );
  background: linear-gradient(to right,rgba(40,47,70,1) 0%,rgba(40,47,70,.1) 100%);
}
.lottery-user-data {
  display: flex;
  // align-items: center;
  .lottery-user-avatar {
    margin-right: 10px;
    img {
      border-radius: 50%;
      overflow: hidden;
      width: 75px;
      height: 75px;
    }
  }
}
.lottery-user .user-right {
  text-align: center;
  .round-span {
    color: #a5aabd;
    font-size: 12px;
  }
  img {
    height: 100px;
  }
}
.weapon-name {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
}
.lottery-user .nick {
  color: #fff;
  float: left;
  word-wrap: break-word;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  overflow: hidden;
  padding-top: 5px;
  font-size: 0.9vw;
  line-height: 35px;
  padding-left: 22px;
}
.lottery-user-name {
  line-height: 40px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
}
.clear {
  clear: both;
}
@media screen and (max-width: 764px) {
  .main {
    background: url("../assets/img/wrapper-bg.jpg") no-repeat 50% 0 #1e2335;
  }
  .timeBox{
    flex-direction: column;
  }
  .lottery {
    padding: 40px 15px;
  }
  .timeBox .left{
    height: 38rem;
  }
  .timeBox .left,.timeBox .right{
    width: 100%;
  }
  .timeBox .left .lottery-skins-img img{
    width: 250px;
  }
  .lottery-user-data .lottery-user-avatar img{
    width: 30px;
    height: 30px;
  }
  .lottery-user-name{
    line-height: normal;
  }
  .lottery-user-name,.weapon-name{
    font-size: 11px;
  }
  .lottery-user .user-right img{
    height: 50px;
  }
}
</style>